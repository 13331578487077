import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UserRoleTableComponent } from '../user-role-table/user-role-table.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'app-user-admin',
    templateUrl: './user-admin.component.html',
    styleUrl: './user-admin.component.scss',
    standalone: true,
    imports: [MatFormField, ReactiveFormsModule, MatLabel, MatInput, UserRoleTableComponent]
})
export class UserAdminComponent {

  form: FormGroup = new FormGroup({
    search: new FormControl('')
  });

  get searchControl() : FormControl{
    return this.form.get('search') as FormControl;
  }

}
