import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { PendingApprovalRequest } from '../models/pending-approval-request';
import { ApprovingTeam } from '../models/approving-team';
import { ApprovedRequest } from '../models/approved-request';

@Injectable({
  providedIn: 'root'
})
export class WikiService {

  constructor(private httpClient: HttpClient) { }

  makeWikiRequest(files: File[], approvingTeamId: number, subject: string, request: string){
    const formData = new FormData();
    files.forEach(file => formData.append('formFiles', file));
    formData.append('approvingTeamId', approvingTeamId.toString());
    formData.append('subject', subject);
    formData.append('request', request);
    return this.httpClient.put(`${environment.apiConfig.uri}api/v1/wiki`, formData);
  }

  approveWikiRequest(approved: boolean, approvalNotes: string, wikiRequestId: number){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/wiki`, {approved: approved, approvalNotes: approvalNotes, wikiRequestId: wikiRequestId});
  }

  getApprovableWikiRequests():Observable<PendingApprovalRequest[]>{
    return this.httpClient.get<PendingApprovalRequest[]>(`${environment.apiConfig.uri}api/v1/wiki`);
  }

  getApprovingTeams():Observable<ApprovingTeam[]>{
    return this.httpClient.get<ApprovingTeam[]>(`${environment.apiConfig.uri}api/v1/wiki/approvingTeams`);
  }

  getApprovedRequests():Observable<ApprovedRequest[]>{
    return this.httpClient.get<ApprovedRequest[]>(`${environment.apiConfig.uri}api/v1/wiki/approvedRequests`);
  }

  closeRequest(wikiRequestId: number){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/wiki/close`, {wikiRequestId: wikiRequestId});
  }

  postApprove(approved: boolean, wikiRequestId: number, notes: string){
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/wiki/postApproval`, {wikiRequestId: wikiRequestId, approved: approved, postApprovalNotes: notes});
  }
}
