import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { DocumentData } from '../../models/document-data';
import { CsaiService } from '../../services/csai.service';
import { AppNotificationService } from '../../services/app-notification.service';
import { MatButton } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-remove-pdf',
    templateUrl: './remove-pdf.component.html',
    styleUrl: './remove-pdf.component.scss',
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, DatePipe]
})
export class RemovePdfComponent {

  documentData: DocumentData[] = [];

  constructor(
    private matDialogRef: MatDialogRef<RemovePdfComponent>,
     @Inject(MAT_DIALOG_DATA) data: DocumentData[], 
     private csaiService: CsaiService,
     private notificationService: AppNotificationService){
    this.documentData = data;
  }

  remove(){
    this.csaiService.deletePdf(this.documentData.map((doc) => doc.id)).subscribe(() => {
      this.notificationService.spawnAlert('Document(s) removed successfully');
    });
    this.matDialogRef.close(true);
  }

  cancel(){
    this.matDialogRef.close();
  }
}
