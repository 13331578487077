import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Game } from '../../models/game';
import { Point } from '../../models/point';
import { Direction } from '../../models/direction';
import { FormControl } from '@angular/forms';
import { AppNotificationService } from '../../services/app-notification.service';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-pong',
    templateUrl: './pong.component.html',
    styleUrl: './pong.component.scss',
    standalone: true,
    imports: [MatIconButton, MatIcon]
})
export class PongComponent implements OnInit, Game, AfterViewInit{
  
  @ViewChild('gameCanvas', { static: true }) gameCanvas!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private logo = new Image();
  gameRunning = false;
  currentScore = 0;
  highScore = 0;
  private logoDirection: Point = { x: 1, y: 1 };
  private requestedKey: KeyboardEvent | null = null;
  private gameInterval: any;
  private zlineLogo?: Point;
  private readonly gridSize: number = 20;
  private direction: Direction = Direction.Right;
  private paddle: Point[] = [{ x: 0, y: 0 }, {x: 0, y: 20}, {x: 0, y: 40}];
  private enemyPaddle: Point[] = [];

  colorCtr: FormControl = new FormControl('blue');
  enemyColorCtr: FormControl = new FormControl('red');

  constructor(private appNotificationService: AppNotificationService){}

  ngOnInit(): void {
    this.highScore = localStorage.getItem('pongHighScore') ? parseInt(localStorage.getItem('pongHighScore')!) : 0;
    this.ctx = this.gameCanvas.nativeElement.getContext('2d')!;
    this.logo.src = 'assets/images/ZlineLogoSmall.png';
  }
    
  ngAfterViewInit(): void {
    this.enemyPaddle = [{ x: this.gameCanvas.nativeElement.width-this.gridSize, y: 0 }, {x: this.gameCanvas.nativeElement.width-this.gridSize, y: 20}, {x: this.gameCanvas.nativeElement.width-this.gridSize, y: 40}];
    this.zlineLogo = { x: this.gameCanvas.nativeElement.width/2, y: this.gameCanvas.nativeElement.height/2 };
  }

  start(){
    this.gameRunning = true;
    this.restart();
  }

  
  gameLoop(){
    this._clearCanvas();
    this._movePaddle();
    this._drawPaddle();
    this._moveLogo();
    this._drawLogo();
    this._checkCollision();
  }

  
  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        this.direction = Direction.Up;
        break;
      case 'ArrowDown':
        this.direction = Direction.Down;
        break;
    }
  }

  private _clearCanvas(){
    this.ctx.clearRect(0, 0, this.gameCanvas.nativeElement.width, this.gameCanvas.nativeElement.height);
  }

  private _drawPaddle(){
    this.ctx.fillStyle = this.colorCtr?.value ?? 'blue';
    for (const part of this.paddle) {
      this.ctx.fillRect(part.x, part.y, this.gridSize, this.gridSize);
    }
    this.ctx.fillStyle = this.enemyColorCtr?.value ?? 'red';
    for (const part of this.enemyPaddle) {
      this.ctx.fillRect(part.x, part.y, this.gridSize, this.gridSize);
    }
  }

  private _moveLogo(){
    this.zlineLogo!.x += this.logoDirection.x * this.gridSize/4;
    this.zlineLogo!.y += this.logoDirection.y * this.gridSize/4;
  }

  private _checkCollision(){
    if(this.zlineLogo!.y === 0 || this.zlineLogo!.y === this.gameCanvas.nativeElement.height-this.gridSize){
      this.logoDirection.y *= -1;
    }
    if(this.zlineLogo!.x === 0){
      this._endGame(); 
    }
    else if (this.zlineLogo!.x === this.gameCanvas.nativeElement.width-this.gridSize){
      this.currentScore++;
      this.zlineLogo!.x = this.gameCanvas.nativeElement.width/2;
    }

    if((this.zlineLogo!.x  <= (this.paddle[0].x+this.gridSize+10)) && this.zlineLogo!.y >= this.paddle[0].y && this.zlineLogo!.y <= this.paddle[this.paddle.length-1].y
      || this.zlineLogo!.x >= (this.enemyPaddle[0].x-this.gridSize-10) && this.zlineLogo!.y >= this.enemyPaddle[0].y && this.zlineLogo!.y <= this.enemyPaddle[this.enemyPaddle.length-1].y
    ){
      this.logoDirection.x *= -1;
    }
  }

  private _movePaddle(){
    switch(this.direction){
      case Direction.Up:
        if(this.paddle[0].y === 0){
          return;
        } 
        this.paddle.forEach(point => {
          point.y -= this.gridSize;
        });
        this.direction = Direction.None;
        break;
      case Direction.Down:
        if(this.paddle[this.paddle.length-1].y === this.gameCanvas.nativeElement.height-this.gridSize){
          return;
        }
        this.paddle.forEach(point => {
          point.y += this.gridSize;
        });
        this.direction = Direction.None; 
        break;
    }

    const predictedLogoPosition = this.zlineLogo!.y + this.logoDirection.y * this.gridSize/4;
    if(predictedLogoPosition > this.enemyPaddle[1].y){
      this.enemyPaddle.forEach(point => {
      point.y += this.gridSize/4;
      });
    }
    
    else if(predictedLogoPosition < this.enemyPaddle[1].y){
      this.enemyPaddle.forEach(point => {
      point.y -= this.gridSize/4;
      });
    };

  }

  private _endGame(){
    this.gameRunning = false;
    clearInterval(this.gameInterval);
    this.appNotificationService.spawnError('Game Over');
    localStorage.setItem('snakeHighScore', Math.max(this.currentScore, this.highScore).toString());
    this.highScore = Math.max(this.currentScore, this.highScore);
  }

  private _drawLogo() {
    this.ctx.drawImage(this.logo , this.zlineLogo!.x-(this.gridSize), this.zlineLogo!.y-(this.gridSize), this.gridSize*3, this.gridSize*3);
  }

  restart(): void {
    this.zlineLogo!.x = this.gameCanvas.nativeElement.width/2;
    this.gameInterval = null;
    this.gameInterval = setInterval(() => this.gameLoop(), 50);
  }

}
