import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuditLogDetails } from '../models/audit-log-details';
import { AuditEntity, AuditLogResponse } from '../models/audit-log';
import { AuditLogGraph } from '../models/audit-log-graph';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(private httpClient: HttpClient) { }

  getAuditLogDetails(fromDate?: Date, toDate?: Date, modelType?: string, integrationType?: string){
    return this.httpClient.get<AuditLogDetails>(`${environment.apiConfig.uri}api/v1/AuditLog/Details?fromDateTime=${fromDate?.toISOString()}&toDateTime=${toDate?.toISOString()}&modelTypes=${modelType}&dataSources=${integrationType}`);
  }
  getAuditLogs(fromDate?: Date, toDate?: Date, modelType?: string, integrationType?: string, success?: boolean | null, int?: number, page?: number, pageSize?: number, orderBy = 'modifyCompletionTimeUtc', orderDirection: 'asc' | 'desc' = 'desc'){
    return this.httpClient.get<AuditLogResponse>
    (`${environment.apiConfig.uri}api/v1/AuditLog
      ?fromDateTime=${fromDate?.toISOString()}
      &toDateTime=${toDate?.toISOString()}
      &modelTypes=${modelType}
      &dataSources=${integrationType}
      &success=${success ?? ''}
      &id=${int ?? ''}
      &page=${page ?? 0}
      &pageSize=${pageSize ?? 50}
      &orderBy=${orderBy}
      &orderByDirection=${orderDirection}`.replace(/\s/g, ''));
  }

  getModelTypes(){
    return this.httpClient.get<string[]>(`${environment.apiConfig.uri}api/v1/AuditLog/ModelTypes`);
  }
  getIntegrationTypes(){
    return this.httpClient.get<string[]>(`${environment.apiConfig.uri}api/v1/AuditLog/Integrations`);
  }
  auditEntities(id: string){
    return this.httpClient.get<AuditEntity[]>(`${environment.apiConfig.uri}api/v1/AuditLog/Entities?id=${id}`);
  }
  getAuditLogGraph(fromDate?: Date, toDate?: Date, modelType?: string, integrationType?: string, timespan?: string){
    return this.httpClient.get<AuditLogGraph[]>
    (`${environment.apiConfig.uri}api/v1/AuditLog/Graph
      ?fromDateTime=${fromDate?.toISOString() ?? ''}
      &toDateTime=${toDate?.toISOString() ?? ''}
      &modelTypes=${modelType ?? ''}
      &dataSources=${integrationType ?? ''}
      &interval=${timespan ?? '1:00:00:00.000'}`.replace(/\s/g, ''));
  }
}
