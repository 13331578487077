<div class="mat-elevation-z8 w-full overflow-y-auto h-[80vh]">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="netsuiteId">
      <th mat-header-cell *matHeaderCellDef>NetSuite ID</th>
      <td mat-cell *matCellDef="let element"> {{element?.netsuiteWarehouseId}} </td>
    </ng-container>

    <ng-container matColumnDef="warehousename">
      <th mat-header-cell *matHeaderCellDef>Warehouse Name</th>
      <td mat-cell *matCellDef="let element"> {{element?.netsuiteWarehouseName}} </td>
    </ng-container>

    <ng-container matColumnDef="assignedlocations">
      <th mat-header-cell *matHeaderCellDef>Assigned Locations</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <mat-form-field class="w-full" [formGroup]="locationForms[i]">
          <mat-label>Shopify Sites</mat-label>
          <mat-select #locationSelect multiple formControlName="assignedLocations">
            <mat-option *ngFor="let domain of allDomains" [value]="domain">{{domain}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div *ngIf="loading.get(i)">
          <mat-spinner [diameter]="24"></mat-spinner>
        </div>
        <div *ngIf="locationForms[i]?.dirty && !loading.get(i)" class="flex items-center">
          <button mat-icon-button matTooltip="Cancel" matTooltipShowDelay="500" (click)="cancelClicked(i)" class="mr-4">
            <mat-icon class="text-red-700">cancel</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Confirm" matTooltipShowDelay="500" (click)="confirmClicked(i)">
            <mat-icon class="text-green-700">check</mat-icon>
          </button>
        </div>
        <div *ngIf="!locationForms[i]?.dirty && !loading.get(i)">
          <button mat-icon-button matTooltip="Edit" matTooltipShowDelay="500" (click)="editClicked(i)">
            <mat-icon class="text-blue-700">edit</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>
</div>