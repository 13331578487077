import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { AppNotificationService } from '../../services/app-notification.service';
import { CsaiService } from '../../services/csai.service';
import { CsaiHistoryService } from '../../services/csai-history.service';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-report-issue',
    templateUrl: './report-issue.component.html',
    styleUrl: './report-issue.component.scss',
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatInput, MatProgressSpinner, MatDialogActions, MatButton]
})
export class ReportIssueComponent implements OnInit {

  form!: FormGroup;
  isSubmitting = false;

  constructor(private matDialog: MatDialogRef<ReportIssueComponent>, private notificationService: AppNotificationService, private csaiService: CsaiService, private csaiHistoryService: CsaiHistoryService){}


  ngOnInit(): void {
    this.form = new FormGroup({
      'promptId': new FormControl('', Validators.required),
      'issue': new FormControl('', Validators.required)
    });
  }

  cancel(){
    this.matDialog.close();
  }

  onConfirm(){
    this.isSubmitting = true;
    this.csaiService.reportIssue(this.form.value.promptId, this.form.value.issue).subscribe(() => {
      this.isSubmitting = false;
      this.notificationService.spawnAlert('Issue reported successfully');
      this.matDialog.close();
    });
  }

  get historicalPrompts(){
    return this.csaiHistoryService.csaiAudits;
  }

  get confirmButtonDisabled(){
    return this.form.invalid;
  }
}
