import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UserResponse } from '../../models/user-response';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { debounceTime, forkJoin} from 'rxjs';
import { AppNotificationService } from '../../services/app-notification.service';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { formIsEquivalent } from '../../extensions';
@Component({
    selector: 'app-user-role-table',
    templateUrl: './user-role-table.component.html',
    styleUrl: './user-role-table.component.scss',
    standalone: true,
    imports: [MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFormField, ReactiveFormsModule, MatLabel, MatSelect, MatOption, MatProgressSpinner, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator]
})


export class UserRoleTableComponent implements OnInit, AfterViewInit, AfterContentChecked{

  allRoles: string[] = [];
  dataSource: MatTableDataSource<UserResponse> = new MatTableDataSource<UserResponse>();

  roleForms: FormGroup[] = [];
  originalResponses: UserResponse[] = [];

  confirmLoading = new Map<number, boolean>();

  @Input() userSearch!: FormControl;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChildren('roleSelect') roleSelects!: QueryList<MatSelect>;

  displayedColumns: string[] = ['id', 'email', 'roles', 'actions'];

  constructor(private cdr: ChangeDetectorRef, private userService: UserService, private notificationService: AppNotificationService) { }

  ngOnInit(): void {
    this.userSearch.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.getUsers();
    });
    this.userService.getAllRoles().subscribe((data) => {
      this.allRoles = data;
    });
  }

  ngAfterViewInit(): void {

    this.cdr.detectChanges();
    this.getUsers();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  paged(_: any){
    //nothing yet. TODO

  }

  getUsers(){
    this.userService.getAllUsers(
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.userSearch.value
    ).subscribe((data) => {
      this.roleForms = [];
      this.originalResponses = [];
      data.forEach((user, i) => {
        const control = new FormGroup({
          id: new FormControl(user.id),
          email: new FormControl(user.email),
          roles: new FormControl(user.roles)
        });
        control.valueChanges.subscribe((newForm) => {
          newForm.roles = newForm.roles?.sort();
          user.roles = user.roles?.sort();
          if(formIsEquivalent(newForm, user)){
            control.markAsPristine();
          }
        });
        this.originalResponses.push(user);
        this.roleForms.push(control);
        this.confirmLoading.set(i, false);
      });
      this.dataSource = new MatTableDataSource<UserResponse>(this.users);
      this.dataSource.paginator = this.paginator;
      this.cdr.detectChanges();
    });
  }

  get users(){
    return this.roleForms.map((control) => control.value);
  }

  editClicked(index: number){
    this.roleSelects.toArray()[index].open();
  }

  cancelClicked(index: number){
    this.roleForms[index].setValue(this.originalResponses[index]);
  }

  confirmClicked(index: number){
    this.confirmLoading.set(index, true);
    const originalRoles = this.originalResponses[index].roles;
    const newRoles = this.roleForms[index].value.roles;
    const email = this.roleForms[index].value.email;

    const rolesToAdd = newRoles.filter((x: string) => !originalRoles.includes(x)) as string[];
    const rolesToRemove = originalRoles.filter((x: string) => !newRoles.includes(x));

    forkJoin([...rolesToAdd.map(x => this.userService.giveUserRole(email, x)), ...rolesToRemove.map(x => this.userService.removeUserRole(email, x))]).subscribe(() => {
      this.notificationService.spawnAlert('User roles updated successfully');
      this.confirmLoading.set(index, false);
      this.roleForms[index].markAsPristine();
      this.getUsers();
    });
  }
}
