import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService {

  constructor(private snackbar: MatSnackBar) { }

  spawnError(message: string){
    this.snackbar.open(`Error: ${message}`, 'Close', {duration: 5000, panelClass:['app-notification-error'], verticalPosition: 'top'});
  }
  spawnAlert(message: string){
    this.snackbar.open(`${message}`, 'Close', {duration: 5000, panelClass:['app-notification-success'], verticalPosition: 'top'});
  }
}
