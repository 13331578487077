<div class="mat-elevation-z8 w-full overflow-y-auto h-[80vh]" >
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef >User Id</th>
        <td mat-cell *matCellDef="let element"> {{element?.id}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> User </th>
        <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
      </ng-container>
  
    <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef > Roles </th>
        <td mat-cell *matCellDef="let element; let i = index"> 
            <mat-form-field class="w-full" [formGroup]="roleForms[i]">
                <mat-label>Roles</mat-label>
                <mat-select #roleSelect multiple formControlName="roles">
                    @for (role of allRoles; track $index) {
                        <mat-option [value]="role">{{role}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef > Actions </th>
        <td mat-cell *matCellDef="let element; let i = index">
            @if(confirmLoading.get(i)){
                <div>
                    <mat-spinner [diameter]="24"></mat-spinner>
                </div>
            }
            @else if(roleForms[i].dirty && !confirmLoading.get(i)){
                <div>
                    <button mat-icon-button matTooltip="Cancel" matTooltipShowDelay="500" (click)="cancelClicked(i)">
                        <mat-icon class="text-red-700">cancel</mat-icon>
                    </button>    
                    <button mat-icon-button matTooltip="Confirm" matTooltipShowDelay="500" (click)="confirmClicked(i)">
                        <mat-icon class="text-green-700">check</mat-icon>
                    </button>
                </div>
            }
            @else if(!roleForms[i].dirty && !confirmLoading.get(i)){
                <div>
                    <button mat-icon-button matTooltip="Edit" matTooltipShowDelay="500" (click)="editClicked(i)">
                        <mat-icon class="text-blue-700">edit</mat-icon>
                    </button>
                </div>
            }
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[25, 50, 100]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements"
                   class="mat-paginator-sticky"
                   (page)="paged($event)">
    </mat-paginator>
  </div>