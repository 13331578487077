import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CsaiService } from '../../services/csai.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppNotificationService } from '../../services/app-notification.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-csai-feedback',
  standalone: true,
  imports: [MatIconModule, MatProgressBarModule, MatTooltipModule, MatButtonModule],
  templateUrl: './csai-feedback.component.html',
  styleUrl: './csai-feedback.component.scss'
})
export class CsaiFeedbackComponent {

  providingFeedback = false;
  feedbackProvided = false;
  
  @Input() promptId!: string;

  constructor(private csaiService: CsaiService, private appNotificationService: AppNotificationService) { }

  feedback(feedback: string){
    this.providingFeedback = true;
    this.csaiService.provideFeedback(this.promptId, feedback).subscribe({
      next: () => {
        this.providingFeedback = false;
        this.feedbackProvided = true;
        this.appNotificationService.spawnAlert('Thanks for Providing Feedback!');
      },
      error: () => {
        this.providingFeedback = false;
        this.appNotificationService.spawnError('Error Providing Feedback, please try again');
      }
    });
  }
}
