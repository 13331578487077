<mat-toolbar class="sticky">
    <div class="flex flex-row justify-between items-center w-full">
        <div class="flex flex-row">
            <button mat-icon-button (click)="toggleDrawer()"><mat-icon class="text-slate-200">menu</mat-icon></button>
            <img src="/assets/images/ZlineLogo.png" class="w-[150px] h-[50px]" alt="ZLINE Logo" />
            <div class="text-red-500 pl-3 mt-2">{{environmentName}}</div>  
        </div>
        <div class="flex flex-row items-center justify-center align-middle">
            <app-callback-notifications></app-callback-notifications>
            <div class="text-slate-200 mr-4">{{name}}</div>
            <button mat-raised-button (click)="logout()"><div>Logout</div></button>
        </div>
    </div>
</mat-toolbar>
  