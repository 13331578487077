<div class="mat-elevation-z8 w-full overflow-y-auto h-[80vh]">
    <div class="action-buttons mb-2">
        <button mat-raised-button (click)="openAddShowroomDialog()">Add Showroom</button>
        <button mat-raised-button (click)="openImportCsvDialog()">Import CSV</button>
    </div>
    <table mat-table [dataSource]="showRoomDataSource">
        <ng-container *ngFor="let column of displayColumns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="column !== 'Actions'; else actionsTemplate">
                    {{ element[column.toLocaleLowerCase()] }}
                </ng-container>
                <ng-template #actionsTemplate>
                    <button mat-icon-button color="warn" (click)="deleteShowroom(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
    </table>
    <div *ngIf="loading" class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
</div>
<div *ngIf="isOperationInProgress" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
</div>