<div class="main-gradient w-full h-full overflow-y-hidden">
    <div class="flex flex-col">
        <div class="flex flex-row m-2" [formGroup]="filterForm">
            <mat-form-field class="pr-2">
                <mat-label>From Date</mat-label>
                <input matInput [matDatepicker]="fromDate" formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>To Date</mat-label>
                <input matInput [matDatepicker]="toDate" formControlName="endDate">
                <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex flex-row h-[40%]">
            <app-csai-prompts-by-user class="w-[33%] m-1" [filterForm]="filterForm"></app-csai-prompts-by-user>
            <app-csai-most-common-prompts class="w-[33%] m-1" [filterForm]="filterForm"></app-csai-most-common-prompts>
            <app-csai-most-common-pdf-responses class="w-[33%] m-1" [filterForm]="filterForm"></app-csai-most-common-pdf-responses>
        </div>
        <div class="h-[40%]">
            <app-csai-prompts-over-time class="w-full h-full m-1" [filterForm]="filterForm"></app-csai-prompts-over-time>
        </div>
    </div>
</div>
