<div #parent>
    @if(auditLogEntities !== null){
        <mat-tab-group mat-stretch-tabs class="p-2" style="width: {{width}}px;" #tabGroup>
            @for (auditLogEntity of auditLogEntities; track $index) {
                <mat-tab label="{{auditLogEntity.modelType +  ' - ' + auditLogEntity.modelId}}">
                    @if(tabGroup.selectedIndex === $index){
                        <div class="flex flex-row h-[50vh] justify-between w-full">
                            <div class="w-[25%]">
                                <mat-list>
                                    <mat-list-item>
                                        <mat-icon matListItemIcon>title</mat-icon>
                                        <h3 matListItemTitle>Id</h3>
                                        <div matListItemLine>
                                            {{auditLogEntity.id}}
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item>
                                        <mat-icon matListItemIcon>adjust</mat-icon>
                                        <h3 matListItemTitle>Model Id</h3>
                                        <div matListItemLine>
                                            {{auditLogEntity.modelId}}
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item>
                                        <mat-icon matListItemIcon>insert_link</mat-icon>
                                        <h3 matListItemTitle>Model Type</h3>
                                        <div matListItemLine>
                                            {{auditLogEntity.modelType}}
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item>
                                        <mat-icon matListItemIcon>functions</mat-icon>
                                        <h3 matListItemTitle>Data Source</h3>
                                        <div matListItemLine>
                                            {{auditLogEntity.dataSource}}
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item>
                                        <mat-icon matListItemIcon>trip_origin</mat-icon>
                                        <h3 matListItemTitle>Entity State</h3>
                                        <div matListItemLine>
                                            {{auditLogEntity.entityState}}
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                            @if(!!auditLogEntity){
                                <div class="w-[75%] ml-2 mt-1 mb-1">
                                    <ace [mode]="'json'" [theme]="'textmate'" [disabled]="true" [value]="jsonPretty(auditLogEntity.payload)"></ace>
                                </div>
                            }
                        </div>
                    }
                </mat-tab>
            }
        </mat-tab-group>
    }
    @else {
        <div class="flex flex-row justify-center">
            <mat-spinner></mat-spinner>
        </div>
    }
</div>
