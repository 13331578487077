import { Component, ViewChild } from '@angular/core';
import { LoadingCallbackService } from '../../services/loading-callback.service';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatBadge } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-callback-notifications',
    templateUrl: './callback-notifications.component.html',
    styleUrl: './callback-notifications.component.scss',
    standalone: true,
    imports: [MatIconButton, MatMenuTrigger, MatIcon, MatBadge, MatMenu, MatProgressSpinner]
})
export class CallbackNotificationsComponent {

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  constructor(private loadingCallbackService: LoadingCallbackService) {
    
  }

  openMenu(){    
    this.loadingCallbackService.viewAll();
  }

  delete(id:string){
    this.loadingCallbackService.remove(id);
  }

  get callbackNotificationCount(){
    return this.loadingCallbackService.loadingCallbacks.length;
  }

  get unreadNotificationCount(){
    return this.loadingCallbackService.loadingCallbacks.filter(x => !x.viewed).length;
  }
  get callbacks(){
    return this.loadingCallbackService.loadingCallbacks;
  }
}
