import * as ace from 'brace';
import 'brace/mode/text';
import 'brace/theme/github';
import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Directive, Optional, Inject, Input, Output, Component, ViewEncapsulation, HostBinding, ViewChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
const ACE_CONFIG = new InjectionToken('ACE_CONFIG');
const AceEditorEvents = ['blur', 'focus', 'copy', 'paste', 'change', 'changeSession'];
const AceSelectionEvents = ['changeCursor', 'changeSelection'];
class AceConfig {
  readOnly;
  cursorStyle;
  selectionStyle;
  mergeUndoDeltas;
  behavioursEnabled;
  highlightActiveLine;
  highlightSelectedWord;
  wrapBehavioursEnabled;
  copyWithEmptySelection;
  navigateWithinSoftTabs;
  autoScrollEditorIntoView;
  mode;
  wrap;
  tabSize;
  overwrite;
  useWorker;
  foldStyle;
  newLineMode;
  useSoftTabs;
  firstLineNumber;
  theme;
  minLines;
  maxLines;
  fontSize;
  fontFamily;
  showGutter;
  printMargin;
  scrollPastEnd;
  animatedScroll;
  showInvisibles;
  fadeFoldWidgets;
  showLineNumbers;
  showFoldWidgets;
  showPrintMargin;
  fixedWidthGutter;
  printMarginColumn;
  displayIndentGuides;
  highlightGutterLine;
  hScrollBarAlwaysVisible;
  vScrollBarAlwaysVisible;
  dragDelay;
  dragEnabled;
  scrollSpeed;
  focusTimeout;
  tooltipFollowsMouse;
  enableBasicAutocompletion;
  enableLiveAutocompletion;
  enableSnippets;
  enableEmmet;
  useElasticTabstops;
  constructor(config = {}) {
    this.assign(config);
  }
  assign(config = {}, target) {
    target = target || this;
    for (const key in config) {
      if (config[key] && !Array.isArray(config[key]) && typeof config[key] === 'object') {
        target[key] = {};
        this.assign(config[key], target[key]);
      } else {
        target[key] = config[key];
      }
    }
  }
}
class AceDirective {
  zone;
  elementRef;
  differs;
  defaults;
  instance = null;
  instanceEventListeners = [];
  selectionEventListeners = [];
  configDiff = null;
  disabled = false;
  config;
  blur = new EventEmitter();
  focus = new EventEmitter();
  copy = new EventEmitter();
  paste = new EventEmitter();
  change = new EventEmitter();
  changeCursor = new EventEmitter();
  changeSession = new EventEmitter();
  changeSelection = new EventEmitter();
  constructor(zone, elementRef, differs, defaults) {
    this.zone = zone;
    this.elementRef = elementRef;
    this.differs = differs;
    this.defaults = defaults;
  }
  ngOnInit() {
    const params = new AceConfig(this.defaults);
    params.assign(this.config); // Custom configuration
    if (this.disabled) {
      params.readOnly = true;
      params.highlightActiveLine = false;
    } else if (params.highlightActiveLine !== false) {
      params.highlightActiveLine = true; // Default
    }
    params.mode = 'ace/mode/' + (params.mode || 'text');
    params.theme = 'ace/theme/' + (params.theme || 'github');
    this.zone.runOutsideAngular(() => {
      this.instance = ace.edit(this.elementRef.nativeElement);
      this.instance.$blockScrolling = Infinity;
      this.instance.setOptions(params);
    });
    // Add native Ace event handling
    AceEditorEvents.forEach(eventName => {
      if (this.instance) {
        const callback = (...args) => {
          if (args.length === 1) {
            args = args[0];
          }
          if (this[eventName]) {
            this.zone.run(() => {
              if (this[eventName].observers.length) {
                this[eventName].emit(args);
              }
            });
          }
        };
        this.instance.on(eventName, callback);
        this.instanceEventListeners.push({
          eventName,
          callback
        });
      }
    });
    // Add native Ace selection event handling
    AceSelectionEvents.forEach(eventName => {
      if (this.instance) {
        const callback = (...args) => {
          if (args.length === 1) {
            args = args[0];
          }
          if (this[eventName]) {
            if (this[eventName].observers.length) {
              this[eventName].emit(args);
            }
          }
        };
        this.instance.selection.on(eventName, callback);
        this.selectionEventListeners.push({
          eventName,
          callback
        });
      }
    });
    if (!this.configDiff) {
      this.configDiff = this.differs.find(this.config || {}).create();
      this.configDiff.diff(this.config || {});
    }
  }
  ngDoCheck() {
    if (this.configDiff) {
      const changes = this.configDiff.diff(this.config || {});
      if (changes) {
        this.ngOnDestroy();
        this.ngOnInit();
      }
    }
  }
  ngOnDestroy() {
    if (this.instance) {
      if (this.instance.isFocused()) {
        this.blur.emit();
      }
      this.instanceEventListeners.forEach(el => {
        this.instance.off(el.eventName, el.callback);
      });
      this.instanceEventListeners = [];
      this.selectionEventListeners.forEach(el => {
        this.instance.selection.off(el.eventName, el.callback);
      });
      this.selectionEventListeners = [];
      delete this.instance;
      this.instance = null;
    }
  }
  ngOnChanges(changes) {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue !== changes['disabled'].previousValue) {
        this.zone.runOutsideAngular(() => {
          if (this.instance) {
            const params = new AceConfig(this.defaults);
            params.assign(this.config); // Custom configuration
            this.instance.clearSelection();
            const hlActive = params.highlightActiveLine !== false ? true : false;
            this.instance.setHighlightActiveLine(this.disabled ? false : hlActive);
            this.instance.setReadOnly(this.disabled ? true : params.readOnly || false);
          }
        });
      }
    }
  }
  ace() {
    return this.instance;
  }
  clear() {
    if (this.instance) {
      this.instance.setValue('');
      this.instance.clearSelection();
    }
  }
  getValue() {
    if (this.instance) {
      return this.instance.getValue();
    }
  }
  setValue(value, cursorPos) {
    if (this.instance) {
      this.instance.setValue(value || '', cursorPos);
    }
  }
  static ɵfac = function AceDirective_Factory(t) {
    return new (t || AceDirective)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.KeyValueDiffers), i0.ɵɵdirectiveInject(ACE_CONFIG, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: AceDirective,
    selectors: [["", "ace", ""]],
    inputs: {
      disabled: "disabled",
      config: [0, "ace", "config"]
    },
    outputs: {
      blur: "blur",
      focus: "focus",
      copy: "copy",
      paste: "paste",
      change: "change",
      changeCursor: "changeCursor",
      changeSession: "changeSession",
      changeSelection: "changeSelection"
    },
    exportAs: ["ngxAce"],
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AceDirective, [{
    type: Directive,
    args: [{
      selector: '[ace]',
      exportAs: 'ngxAce'
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: i0.ElementRef
  }, {
    type: i0.KeyValueDiffers
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [ACE_CONFIG]
    }]
  }], {
    disabled: [{
      type: Input
    }],
    config: [{
      type: Input,
      args: ['ace']
    }],
    blur: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    copy: [{
      type: Output
    }],
    paste: [{
      type: Output
    }],
    change: [{
      type: Output
    }],
    changeCursor: [{
      type: Output
    }],
    changeSession: [{
      type: Output
    }],
    changeSelection: [{
      type: Output
    }]
  });
})();
class AceComponent {
  content = '';
  get value() {
    return this.content;
  }
  set value(value) {
    this.setContent(value);
  }
  disabled = false;
  mode = '';
  theme = '';
  config;
  useAceClass = true;
  blur = new EventEmitter();
  focus = new EventEmitter();
  copy = new EventEmitter();
  paste = new EventEmitter();
  change = new EventEmitter();
  valueChange = new EventEmitter();
  changeCursor = new EventEmitter();
  changeSession = new EventEmitter();
  changeSelection = new EventEmitter();
  directiveRef;
  constructor() {}
  ngAfterViewInit() {
    if (this.content != null) {
      this.setContent(this.content, true);
    }
  }
  setContent(value, force) {
    if (force || value !== this.content) {
      if (this.directiveRef) {
        this.directiveRef.setValue(value, 1);
      }
      this.content = value;
    }
  }
  getConfig() {
    this.config = this.config || {};
    this.config.mode = this.mode || this.config.mode;
    this.config.theme = this.theme || this.config.theme;
    return this.config;
  }
  onContentChange(event) {
    if (this.directiveRef) {
      this.change.emit(event);
      this.content = this.directiveRef.getValue() || '';
      this.valueChange.emit(this.value);
    }
  }
  static ɵfac = function AceComponent_Factory(t) {
    return new (t || AceComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: AceComponent,
    selectors: [["ace"]],
    viewQuery: function AceComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(AceDirective, 7);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.directiveRef = _t.first);
      }
    },
    hostVars: 2,
    hostBindings: function AceComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("ace", ctx.useAceClass);
      }
    },
    inputs: {
      value: "value",
      disabled: "disabled",
      mode: "mode",
      theme: "theme",
      config: "config",
      useAceClass: "useAceClass"
    },
    outputs: {
      blur: "blur",
      focus: "focus",
      copy: "copy",
      paste: "paste",
      change: "change",
      valueChange: "valueChange",
      changeCursor: "changeCursor",
      changeSession: "changeSession",
      changeSelection: "changeSelection"
    },
    exportAs: ["ngxAce"],
    decls: 1,
    vars: 2,
    consts: [[3, "blur", "focus", "copy", "paste", "change", "changeCursor", "changeSession", "changeSelection", "ace", "disabled"]],
    template: function AceComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("blur", function AceComponent_Template_div_blur_0_listener($event) {
          return ctx.blur.emit($event);
        })("focus", function AceComponent_Template_div_focus_0_listener($event) {
          return ctx.focus.emit($event);
        })("copy", function AceComponent_Template_div_copy_0_listener($event) {
          return ctx.copy.emit($event);
        })("paste", function AceComponent_Template_div_paste_0_listener($event) {
          return ctx.paste.emit($event);
        })("change", function AceComponent_Template_div_change_0_listener($event) {
          return ctx.onContentChange($event);
        })("changeCursor", function AceComponent_Template_div_changeCursor_0_listener($event) {
          return ctx.changeCursor.emit($event);
        })("changeSession", function AceComponent_Template_div_changeSession_0_listener($event) {
          return ctx.changeSession.emit($event);
        })("changeSelection", function AceComponent_Template_div_changeSelection_0_listener($event) {
          return ctx.changeSelection.emit($event);
        });
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵproperty("ace", ctx.getConfig())("disabled", ctx.disabled);
      }
    },
    dependencies: [AceDirective],
    styles: [".ace,.ace>.ace_editor{display:block;width:100%;height:100%}.ace.flex{display:flex;flex-direction:inherit;align-items:inherit;min-width:0;min-height:0}.ace.flex>.ace_editor{flex:1 1 auto;min-width:0;min-height:0;-webkit-box-flex:1}\n"],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AceComponent, [{
    type: Component,
    args: [{
      selector: 'ace',
      exportAs: 'ngxAce',
      encapsulation: ViewEncapsulation.None,
      template: "<div [ace]=\"getConfig()\" [disabled]=\"disabled\" (blur)=\"blur.emit($event)\" (focus)=\"focus.emit($event)\" (copy)=\"copy.emit($event)\" (paste)=\"paste.emit($event)\" (change)=\"onContentChange($event)\" (changeCursor)=\"changeCursor.emit($event)\" (changeSession)=\"changeSession.emit($event)\" (changeSelection)=\"changeSelection.emit($event)\">\n</div>\n",
      styles: [".ace,.ace>.ace_editor{display:block;width:100%;height:100%}.ace.flex{display:flex;flex-direction:inherit;align-items:inherit;min-width:0;min-height:0}.ace.flex>.ace_editor{flex:1 1 auto;min-width:0;min-height:0;-webkit-box-flex:1}\n"]
    }]
  }], () => [], {
    value: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    useAceClass: [{
      type: HostBinding,
      args: ['class.ace']
    }, {
      type: Input
    }],
    blur: [{
      type: Output
    }],
    focus: [{
      type: Output
    }],
    copy: [{
      type: Output
    }],
    paste: [{
      type: Output
    }],
    change: [{
      type: Output
    }],
    valueChange: [{
      type: Output
    }],
    changeCursor: [{
      type: Output
    }],
    changeSession: [{
      type: Output
    }],
    changeSelection: [{
      type: Output
    }],
    directiveRef: [{
      type: ViewChild,
      args: [AceDirective, {
        static: true
      }]
    }]
  });
})();
class AceModule {
  static ɵfac = function AceModule_Factory(t) {
    return new (t || AceModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AceModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AceModule, [{
    type: NgModule,
    args: [{
      declarations: [AceComponent, AceDirective],
      imports: [CommonModule],
      exports: [CommonModule, AceComponent, AceDirective],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ACE_CONFIG, AceComponent, AceConfig, AceDirective, AceModule };
