import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-showroom-delete-component',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  templateUrl: './showroom-delete.component.html',
  styleUrl: './showroom-delete.component.scss'
})
export class ShowroomDeleteComponent {
  constructor(public dialogRef: MatDialogRef<ShowroomDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
