<div class="p-6">
  <h2 class="text-2xl font-bold mb-6">Add New Showroom</h2>
  <form [formGroup]="showroomForm" (ngSubmit)="onSubmit()">
    <table mat-table [dataSource]="formFields" class="w-full">
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef class="w-1/3"> </th>
        <td mat-cell *matCellDef="let field" class="py-4">
          {{field.label}}
          <span *ngIf="field.required" class="text-red-500 ml-1">*</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="input">
        <th mat-header-cell *matHeaderCellDef class="w-2/3"> </th>
        <td mat-cell *matCellDef="let field" class="py-4">
          <mat-form-field class="w-full">
            <input matInput [formControlName]="field.name" [required]="field.required" [placeholder]="field.label">
            <mat-error *ngIf="showroomForm.get(field.name)?.invalid && showroomForm.get(field.name)?.touched">
              {{field.label}} is required
            </mat-error>
          </mat-form-field>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="hidden"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="flex justify-end space-x-4 mt-6">
      <button mat-button [mat-dialog-close]="null" type="button">
        Cancel
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="!showroomForm.valid">
        Add Showroom
      </button>
    </div>
  </form>
</div>