<div class="main-gradient w-full h-full flex flex-col">
    <div class="flex flex-col">
        <div class="flex flex-row justify-start align-middle m-4" [formGroup]="formGroup">
            <mat-form-field>
                <mat-label>From Date</mat-label>
                <input formControlName="startDate" matInput [matDatepicker]="fromDate">
                <mat-hint class="text-slate-200">MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
            <div class="text-4xl text-center text-slate-200 pl-4 pr-4">-</div>
            <mat-form-field>
                <mat-label>To Date</mat-label>
                <input formControlName="endDate" matInput [matDatepicker]="toDate">
                <mat-hint class="text-slate-200">MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="toDate"></mat-datepicker-toggle>
                <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="pl-4">
                <mat-label>Model Type</mat-label>
                <mat-select formControlName="modelTypeSelection" multiple>
                    @for(modelType of modelTypes; track $index) {
                        <mat-option [value]="modelType">{{modelType}}</mat-option>
                    }
                  </mat-select>
                  <mat-hint><mat-icon class="text-slate-200" matTooltip="If None are selected, it will search all model types">information</mat-icon></mat-hint>
            </mat-form-field>
            <mat-form-field class="pl-4">
                <mat-label>Integration</mat-label>
                <mat-select formControlName="integrationTypeSelection" multiple>
                    @for(integrationType of integrationTypes; track $index){
                        <mat-option [value]="integrationType">{{integrationType}}</mat-option>                
                    }
                </mat-select>
                <mat-hint><mat-icon class="text-slate-200" matTooltip="If None are selected, it will search all integrations">information</mat-icon></mat-hint>
            </mat-form-field>
            <mat-form-field class="pl-4">
                <mat-label>Success</mat-label>
                <mat-select formControlName="successSelection">
                    <mat-option [value]="">None</mat-option>
                    @for(success of ['Unknown','False','True']; track $index){
                        <mat-option [value]="success">{{success}}</mat-option>                
                    }
                </mat-select>
                <mat-hint><mat-icon class="text-slate-200" matTooltip="If None are selected, it will search all states">information</mat-icon></mat-hint>
            </mat-form-field>
            <mat-form-field class="pl-4">
                <mat-label>Id</mat-label>
                <input type="number" matInput formControlName="idSelection">
                <mat-hint><mat-icon class="text-slate-200" matTooltip="If left empty, will search all ids">information</mat-icon></mat-hint>
            </mat-form-field>
        </div>
        <div class="flex flex-row mr-2">
            <div class="w-[25%] m-2 flex flex-col">
                <app-audit-card [integrationForm]="formGroup" class="min-h-[35%]"></app-audit-card>
                <app-audit-success-graph [integrationForm]="formGroup" class="mt-2"></app-audit-success-graph>
            </div>
            <div class="w-[75%] m-2 overflow-auto">
                <app-audit-table [integrationForm]="formGroup"></app-audit-table>
            </div>
        </div>
    </div>
</div>