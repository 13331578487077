<div class="mat-elevation-z8 w-full overflow-y-auto h-[80vh]" matSort >
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element;">
          <button mat-icon-button (click)="rowClicked(element); $event.stopPropagation()">
            @if(selectedRow === element){
              <mat-icon>keyboard_arrow_up</mat-icon>
            }
            @else {
              <mat-icon>keyboard_arrow_down</mat-icon>
            }
          </button>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id">Audit Id</th>
        <td mat-cell *matCellDef="let element"> {{element?.id}} </td>
      </ng-container>

      <ng-container matColumnDef="modifyStartTimeUtc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Start Time"> Modify Start Time </th>
        <td mat-cell *matCellDef="let element"> {{element?.modifyStartTimeUtc | date:'MM/dd/YYYY hh:mm:ss'}} </td>
      </ng-container>
  
    <ng-container matColumnDef="modifyCompletionTimeUtc" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by End Time"> Modify End Time </th>
        <td mat-cell *matCellDef="let element"> {{element?.modifyCompletionTimeUtc | date:'MM/dd/YYYY hh:mm:ss'}} </td>
    </ng-container>

    <ng-container matColumnDef="database">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Database"> Database </th>
        <td mat-cell *matCellDef="let element"> {{element?.database}} </td>
    </ng-container>

    <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Message"> Message </th>
        <td mat-cell *matCellDef="let element"> {{element?.message}} </td>
    </ng-container>

    <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Success"> Success </th>
        <td mat-cell *matCellDef="let element"><mat-icon [ngClass]="element?.success ? 'text-green-700' : element?.success === false ? 'text-red-700' : 'text-yellow-700'">
          {{element?.success ? 'check_circle_outline' : element?.success === false ? 'error_outline' : 'warning'}}
        </mat-icon></td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element;" [attr.colspan]="displayedColumns.length">
        <div [@detailExpand]="element === selectedRow ? 'expanded' : 'collapsed'">
          <app-audit-details #auditDetails [auditLogId]="element?.id"></app-audit-details>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row 
        (click)="rowClicked(row)"
        [class.selected]="row === selectedRow"
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="data-row"></tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[25, 50, 100]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements"
                   class="mat-paginator-sticky"
                   (page)="paged($event)">
    </mat-paginator>
  </div>