import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SpeechService } from '../../services/speech.service';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-speech-setting',
    templateUrl: './speech-setting.component.html',
    styleUrl: './speech-setting.component.scss',
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatSlider, MatSliderThumb, MatDialogActions, MatButton]
})
export class SpeechSettingComponent implements OnInit{

  form!: FormGroup;

  constructor(private speechService: SpeechService, private matDialogRef: MatDialogRef<SpeechSettingComponent>) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      rate: new FormControl(this.speechService.rate),
      pitch: new FormControl(this.speechService.pitch),
      volume: new FormControl(this.speechService.volume),
      voiceName: new FormControl(this.speechService.voiceName)
    });
  }

  cancel(){
    this.matDialogRef.close();
  }
  
  onConfirm(){
    this.matDialogRef.close();

    this.speechService.rate = this.form.get('rate')?.value;
    this.speechService.pitch = this.form.get('pitch')?.value;
    this.speechService.volume = this.form.get('volume')?.value;
    this.speechService.voiceName = this.form.get('voiceName')?.value;

    localStorage.setItem('rate', this.speechService.rate.toString());
    localStorage.setItem('pitch', this.speechService.pitch.toString());
    localStorage.setItem('volume', this.speechService.volume.toString());
    localStorage.setItem('voiceName', this.speechService.voiceName ?? '');
  }

  get confirmButtonDisabled(){
    return this.speechService.rate === this.form.get('rate')?.value && this.speechService.pitch === this.form.get('pitch')?.value && this.speechService.volume === this.form.get('volume')?.value && this.speechService.voiceName === this.form.get('voiceName')?.value;
  }

  get distinctVoiceNames(){
    return this.speechService.voices.map(x => x.name);
  }

  get rateValue(){
    return this.form.get('rate')?.value;
  }
  get volumeValue(){
    return this.form.get('volume')?.value;
  }
  get pitchValue(){
    return this.form.get('pitch')?.value;
  }
}
