import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ArcadeComponent } from '../arcade/arcade.component';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-loading-logo',
    templateUrl: './loading-logo.component.html',
    styleUrl: './loading-logo.component.scss',
    standalone: true,
    imports: [MatButton]
})
export class LoadingLogoComponent {

  constructor(private matDialog: MatDialog) { }

  @Input() includeGame = false;

  logoClicked(){
    if(this.includeGame){
      this.matDialog.open(ArcadeComponent, {
        width: '99%',
        height: '70%'
      });
    }
  }
}
