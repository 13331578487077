<div class="h-full bg-slate-800 text-2xl text-slate-200 text-center align-middle">
    @if(loadingHistory){
        <div class="flex flex-row justify-center">
            <mat-spinner></mat-spinner>
        </div>
    }
    @else {
        <div class="flex flex-col">
            <mat-form-field class="w-full p-2">
                <mat-label>Search</mat-label>
                <input [formControl]="searchForm" matInput placeholder="Ex. What should I do if my dishwasher leaks from one side?" />
            </mat-form-field>
            <mat-action-list>
                @for(csaiAudit of filteredAudits; track $index){
                    <button mat-list-item (click)="selected(csaiAudit)" matTooltip="{{csaiAudit.prompt}}" matTooltipShowDelay="250" class="min-h-[100px]">
                        <div class="flex flex-col">
                            <div class="flex flex-row justify-start items-center overflow-x-hidden">
                                <mat-icon class="mr-4 text-slate-200">search</mat-icon>
                                <div class="text-slate-200 truncate">
                                    {{getHistoricalPrompt(csaiAudit.prompt)}}
                                </div>
                            </div>
                            <div class="flex flex-row justify-start items-center">
                                <mat-icon class="mr-4 text-slate-200">date_range</mat-icon>
                                <div class="text-slate-200">
                                    {{csaiAudit.createdDateUtc | date: 'short'}}
                                </div>
                            </div>
                        </div>
                    </button>
                }         
            </mat-action-list>
        </div>
    }
</div>