<h2 mat-dialog-title class="text-center">Report Issue</h2>
<mat-dialog-content class="flex flex-row justify-center items-center w-full" [formGroup]="form">
    @if(!isSubmitting){
        <mat-form-field class="w-full">
            <mat-label>Prompt With Issue</mat-label>
            <mat-select formControlName="promptId" required>
                @for (historicalPrompt of historicalPrompts; track $index) {
                    <mat-option [value]="historicalPrompt.id">{{historicalPrompt.prompt}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Issue</mat-label>
            <textarea matInput formControlName="issue" placeholder="Ex. This response was terrible! Here is how it can be improved..." required></textarea>
        </mat-form-field>
    }
    @else {
        <div class="flex flex-row justify-center">
            <mat-spinner></mat-spinner>
        </div>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button class="w-[48.5%]" mat-raised-button color="warn" (click)="cancel()" cdkFocusInitial>Cancel</button>
    <button class="w-[48.5%]" mat-raised-button [disabled]="confirmButtonDisabled" (click)="onConfirm()" [disabled]="isSubmitting">Report Issue</button>
</mat-dialog-actions>
