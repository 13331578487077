import { Component, ViewChild } from '@angular/core';
import { SnakeComponent } from '../snake/snake.component';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { PongComponent } from '../pong/pong.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatButton } from '@angular/material/button';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-arcade',
    templateUrl: './arcade.component.html',
    styleUrl: './arcade.component.scss',
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatTabGroup, MatTab, SnakeComponent, PongComponent, MatDialogActions, MatButton]
})
export class ArcadeComponent {

  constructor(private matDialogRef: MatDialogRef<SnakeComponent>){}
  
  @ViewChild(SnakeComponent) snakeComponent!: SnakeComponent;
  @ViewChild(PongComponent) pongComponent!: PongComponent;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  leave(){
    this.matDialogRef.close();
  }
  
  restart(){
    if(this.tabGroup.selectedIndex === 0){
      this.snakeComponent.restart();
    } else {
      this.pongComponent.restart();
    }
  }

}
