import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AuditEntity } from '../../models/audit-log';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { AuditLogService } from '../../services/audit-log.service';
import 'brace';
import 'brace/mode/json';
import 'brace/theme/textmate';
import { JsonPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AceModule } from 'ngx-ace-wrapper';
import { MatIcon } from '@angular/material/icon';
import { MatList, MatListItem, MatListItemIcon, MatListItemTitle, MatListItemLine } from '@angular/material/list';
import { MatTabGroup, MatTab } from '@angular/material/tabs';

@Component({
    selector: 'app-audit-details',
    templateUrl: './audit-details.component.html',
    styleUrl: './audit-details.component.scss',
    providers: [JsonPipe],
    standalone: true,
    imports: [MatTabGroup, MatTab, MatList, MatListItem, MatIcon, MatListItemIcon, MatListItemTitle, MatListItemLine, AceModule, MatProgressSpinner]
})
export class AuditDetailsComponent implements AfterViewInit {

  @ViewChild('parent') parent!: ElementRef;

  constructor(private auditLogService: AuditLogService, private jsonPipe: JsonPipe) { }

  ngAfterViewInit(): void {
    this.width = this.parent.nativeElement.offsetWidth.toString();
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( (eve) => {
      //HACK. this is some weird styling error where the mat tab group can't be resized properly without a static width. This cheats the system a little but works pretty well.
      this.width = ((eve.currentTarget as any).innerWidth * 0.7).toString();
    });
  }

  resizeObservable$!: Observable<Event>
  resizeSubscription$!: Subscription
  
  @Input() auditLogId!: string;
  width: string | null = null;

  auditLogEntities: AuditEntity[] | null = null;

  loadData(){
    if(this.auditLogEntities === null){
      this.auditLogService.auditEntities(this.auditLogId).subscribe((data) => {
        this.auditLogEntities = data;
      })
    }
  }

  jsonPretty(json: string){
    json = json.replaceAll('"', '"');
    const obj = JSON.parse(json);
    json = this.jsonPipe.transform(obj);
    return json;
  }

}
