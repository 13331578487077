import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { ValidateResponse } from '../models/validate-response';
import { UserResponse } from '../models/user-response';
import { BaseResponse } from '../models/base-response';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private httpClient: HttpClient;
  constructor(private httpBackend: HttpBackend, private dependencyInjectedClient: HttpClient) {
    this.httpClient = new HttpClient(httpBackend);
   }

  validate(token: string): Observable<ValidateResponse>{
    return this.httpClient.get<ValidateResponse>(`${environment.apiConfig.uri}api/v1/User/Validate`, { headers: {Authorization: `Bearer ${token}`}});
  }

  getUserRoles(userEmail: string): Observable<ValidateResponse>{ 
    return this.dependencyInjectedClient.get<ValidateResponse>(`${environment.apiConfig.uri}api/v1/User/UserRoles?userEmail=${userEmail}`);
  }

  getAllUsers(page: number, pageSize: number, emailFilter?: string): Observable<UserResponse[]>{
    return this.dependencyInjectedClient.get<UserResponse[]>(`${environment.apiConfig.uri}api/v1/User?Page=${page}&PageSize=${pageSize}&OrderBy=email&OrderByDirection=DESC&EmailFilter=${emailFilter ?? ''}`);
  }

  getAllRoles(): Observable<string[]>{ 
    return this.dependencyInjectedClient.get<string[]>(`${environment.apiConfig.uri}api/v1/User/Role`);
  }

  giveUserRole(user: string, role: string) : Observable<BaseResponse>{
    return this.dependencyInjectedClient.post<BaseResponse>(`${environment.apiConfig.uri}api/v1/User/Role`, {email: user, role: role});
  }

  removeUserRole(user: string, role: string): Observable<BaseResponse>{
    return this.dependencyInjectedClient.delete<BaseResponse>(`${environment.apiConfig.uri}api/v1/User/Role`, {body: {email: user, role: role}});
  }
}
