<mat-form-field [formGroup]="searchForm" class="w-full">
  <mat-label>Search</mat-label>
  <input matInput formControlName="search" placeholder="Search">
</mat-form-field>
@if (loading) {
  <div class="flex flex-row justify-center align-middle">
    <mat-spinner></mat-spinner>
  </div>
}
<mat-accordion color="primary">
  @for(documentData of filteredDocuments; track $index){
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{documentData.parentName}}
        </mat-panel-title>
        <mat-panel-description>
          <div class="flex flex-row justify-between align-middle text-center w-full">
            <div class="pt-3">
              {{documentData.createdBy}} - {{documentData.createdDate | date:'MM/dd/yyyy'}}
            </div>
            @if(!!documentData.documents[0].sku){
              Sku: {{documentData.documents[0].sku}}
            }
            <button mat-icon-button (click)="openRemovePdf(documentData.documents)">
              <mat-icon class="mat-icon-rtl-mirror">
                remove_circle
              </mat-icon>
            </button>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="flex flex-col justify-center align-middle">
        @for(doc of documentData.documents; track $index){
          <div>
            <div class="flex flex-row justify-between p-2 w-full">
              <div class="flex flex-row">
                <button mat-icon-button matTooltip="Preview Document" [matTooltipShowDelay]="500" (click)="documentSelected(doc)">
                  <mat-icon>description</mat-icon>
                </button>  
                <div class="pt-3 text-xl">
                  {{doc.blobStorageName}}
                </div>
                <div class="pt-3 pl-4">
                  {{getContentPreview(doc)}}
                </div>
              </div>
              <button mat-icon-button (click)="openRemovePdf([doc])">
                <mat-icon class="mat-icon-rtl-mirror">
                  remove_circle
                </mat-icon>
              </button>
            </div>
            <mat-divider></mat-divider>
          </div>
        }
      </div>
    </mat-expansion-panel>
  }
</mat-accordion>