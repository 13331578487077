<div class="main-gradient w-100 h-full">
    <div class="flex flex-col justify-center align-middle h-full">
        <div class="flex flex-row justify-center align-middle mb-32">
            <app-loading-logo [includeGame]="true"></app-loading-logo>
        </div>
        <div class="flex flex-row justify-center align-middle">
            <button mat-raised-button (click)="login()" [disabled]="isLoggingIn">
                @if(!isLoggingIn){
                    <div>
                        Login
                    </div>
                }
                @else {
                    <mat-spinner [diameter]="20"></mat-spinner>
                }
            </button>
        </div>
    </div>
</div>
