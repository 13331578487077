import { Component, Input, OnInit } from '@angular/core';
import { AuditLogGraph } from '../../models/audit-log-graph';
import { AuditLogService } from '../../services/audit-log.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Color, ScaleType, AreaChartModule } from '@swimlane/ngx-charts';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';

@Component({
    selector: 'app-audit-success-graph',
    templateUrl: './audit-success-graph.component.html',
    styleUrl: './audit-success-graph.component.scss',
    providers: [DatePipe],
    standalone: true,
    imports: [MatCard, MatCardHeader, MatCardTitle, MatIconButton, MatTooltip, MatMenuTrigger, MatIcon, MatCardContent, MatProgressSpinner, AreaChartModule, MatMenu, ReactiveFormsModule, MatFormField, MatLabel, MatInput]
})
export class AuditSuccessGraphComponent implements OnInit {

  timelineForm: FormGroup = new FormGroup({
    days: new FormControl('7'),
    hours: new FormControl('0'),
    minutes: new FormControl('0'),
    seconds: new FormControl('0'),
    milliseconds: new FormControl('0')
  });

  colorScheme: Color = {
    domain: ['#5AA454', '#A10A28'],
    name: 'Base',
    selectable: false,
    group: ScaleType.Time
  };


  data: AuditLogGraph[] = [];
  isLoading = false;

  @Input() integrationForm!: FormGroup;

  constructor(private auditLogService: AuditLogService, private datePipe: DatePipe){ }

  ngOnInit(): void {
    this.getAuditLogGraph();
    
    this.integrationForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.getAuditLogGraph();
    });
    this.timelineForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.getAuditLogGraph();
    });
  }

  getAuditLogGraph(){
    this.isLoading = true;
    this.auditLogService.getAuditLogGraph(
      this.integrationForm.get('startDate')?.value, 
      this.integrationForm.get('endDate')?.value,
      this.integrationForm.get('modelTypeSelection')?.value,
      this.integrationForm.get('integrationTypeSelection')?.value,
      this.timelineToString
    ).subscribe(data => {
      this.data = data;
      this.data.forEach(datum => {
        datum.series.forEach(series => {
          series.name = this.datePipe.transform(series.name, 'mediumDate') ?? '';
        });
      })
      this.isLoading = false;
    });
  }


  get timelineToString(){
    return `${this.timelineForm.get('days')?.value}:${this.timelineForm.get('hours')?.value}:${this.timelineForm.get('minutes')?.value}:${this.timelineForm.get('seconds')?.value}.${this.timelineForm.get('milliseconds')?.value}`;
  }
}

