<h2 mat-dialog-title class="text-center">Remove PDF</h2>
<mat-dialog-content class="flex flex-row justify-center items-center w-full">
    <div class="text-center">
        <p class="text-lg text-red-700">Are you sure you want to remove the following data?</p>
        @for (document of documentData; track $index) {
            <div>
                <div class="flex flex-row justify-between w-full items-center">
                    <div class="pt-3 text-xl">
                        {{document.createdBy}} - {{document.createdDateTimeUtc | date:'MM/dd/yyyy'}}
                    </div>
                    <div>
                        {{document.blobStorageName}}
                    </div>
                </div>
            </div>
        }   
        <p class="text-xl text-red-700">This Action Cannot be Undone!</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="w-[48.5%]" mat-raised-button color="primary" (click)="cancel()" cdkFocusInitial>Cancel</button>
    <button class="w-[48.5%]" mat-raised-button color="warn" (click)="remove()">Remove</button>
</mat-dialog-actions>
