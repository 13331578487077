import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Showroom } from "../models/showroom";
import { BaseResponse } from "../models/base-response";

@Injectable({
    providedIn: 'root'
})

export class ShowroomService {
    constructor(private httpClient : HttpClient) { }

    getShowrooms(): Observable<Showroom[]>{
        return this.httpClient.get<Showroom[]>(`${environment.apiConfig.uri}api/v1/DealerProduct/GetShowrooms`);
    }

    addShowroom(showroom: Showroom): Observable<Showroom>{
        return this.httpClient.post<Showroom>(`${environment.apiConfig.uri}api/v1/DealerProduct/AddShowroom`, showroom);
    }

    deleteShowroom(showroomId: number): Observable<BaseResponse> {
        return this.httpClient.delete<BaseResponse>(
            `${environment.apiConfig.uri}api/v1/DealerProduct/DeleteShowroom`,
            { params: { showroomId: showroomId.toString() } }
        );
    }

    processCsv(file: FormData){
        return this.httpClient.post<FormData>(`${environment.apiConfig.uri}api/v1/DealerProduct/ShowroomsUpload`, file);
    }
}