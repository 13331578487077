import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ValidateResponse } from '../models/validate-response';
import { concatMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private _roles: string[] | null = null;

  constructor(private httpClient: HttpClient) { }


  refreshRoles(){
    return this.httpClient.get<ValidateResponse>(`${environment.apiConfig.uri}api/v1/User/Validate`).pipe(concatMap((res) => {
      return of(this._roles = res.roles);
    }));
  }

  get roles(){
    return this._roles;
  }
}
