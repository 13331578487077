import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-google-sheet-embed',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './google-sheet-embed.component.html',
  styleUrl: './google-sheet-embed.component.scss'
})
export class GoogleSheetEmbedComponent {

  googleSheetUrl = '';

  constructor(private matDialog: MatDialogRef<GoogleSheetEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) data: string
  ){
    this.googleSheetUrl = data;
  }

  exit(){
    this.matDialog.close();
  }

}
