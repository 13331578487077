import { Component } from '@angular/core';
import { MatTab, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { WikiRequestReviewTableComponent } from "../wiki-request-review-table/wiki-request-review-table.component";
import { MatLabel } from '@angular/material/form-field';
import { ApprovedRequestsTableComponent } from '../approved-requests-table/approved-requests-table.component';

@Component({
  selector: 'app-manage-wiki-requests',
  standalone: true,
  imports: [MatTabsModule, MatTab, WikiRequestReviewTableComponent, WikiRequestReviewTableComponent, MatLabel, MatTabGroup, ApprovedRequestsTableComponent],
  templateUrl: './manage-wiki-requests.component.html',
  styleUrl: './manage-wiki-requests.component.scss'
})
export class ManageWikiRequestsComponent {

}
