import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ShopifyWarehouse, WarehouseLocation } from '../../models/location-mapping-data';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LocationMappingService } from '../../services/location-mapping.service';
import { AppNotificationService } from '../../services/app-notification.service';
import { forkJoin, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { formIsEquivalent } from '../../extensions';
import { BaseResponse } from '../../models/base-response';

@Component({
  selector: 'app-location-mapping-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule
  ],
  templateUrl: './location-mapping-table.component.html',
  styleUrls: ['./location-mapping-table.component.scss']
})
export class LocationMappingTableComponent implements OnInit, AfterViewInit, AfterContentChecked {
  allDomains: string[] = [];
  dataSource: WarehouseLocation[] = [];

  locationForms: FormGroup[] = [];
  originalResponse: WarehouseLocation[] = [];

  loading = new Map<number, boolean>();

  @ViewChildren('locationSelect') locationSelect!: QueryList<MatSelect>;

  displayedColumns: string[] = ['netsuiteId', 'warehousename', 'assignedlocations', 'actions'];

  constructor(private cdr: ChangeDetectorRef, private locationMappingService: LocationMappingService, private notifService: AppNotificationService) { }

  ngOnInit(): void {
    this.locationMappingService.getDomains().subscribe((data) => {
      this.allDomains = data;
    });
    this.getLocationMappings();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.getLocationMappings();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  getLocationMappings() {
    this.locationMappingService.getLocationMappings()
      .subscribe((data) => {
        this.locationForms = [];
        this.originalResponse = [];
        data.forEach((location, i) => {
          const control = new FormGroup({
            id: new FormControl(location.netsuiteWarehouseId),
            warehouseName: new FormControl(location.netsuiteWarehouseName),
            assignedLocations: new FormControl(location.shopifyWarehouse.map(sw => sw.shopifyDomainName))
          });
          control.valueChanges.subscribe((newForm) => {
            newForm.assignedLocations = newForm.assignedLocations?.sort();
            location.shopifyWarehouse = location.shopifyWarehouse?.sort((a, b) => a.shopifyDomainName.localeCompare(b.shopifyDomainName));
            if (formIsEquivalent(newForm, this.transformWarehouseLocation(location))) {
              control.markAsPristine();
            }
          });
          this.originalResponse.push(location);
          this.locationForms.push(control);
          this.loading.set(i, false);
        });
        this.dataSource = data;
      });
  }

  editClicked(index: number) {
    this.locationSelect.toArray()[index].open();
  }

  transformWarehouseLocation(location: WarehouseLocation): Partial<{
    id: number | null;
    warehouseName: string | null;
    assignedLocations: string[] | null;
  }> {
    return {
      id: location.netsuiteWarehouseId,
      warehouseName: location.netsuiteWarehouseName,
      assignedLocations: location.shopifyWarehouse.map(sw => sw.shopifyDomainName)
    };
  }

  cancelClicked(index: number) {
    const originalData = this.originalResponse[index];
    const formValue = {
      id: originalData.netsuiteWarehouseId,
      warehouseName: originalData.netsuiteWarehouseName,
      assignedLocations: originalData.shopifyWarehouse.map(sw => sw.shopifyDomainName)
    };
    this.locationForms[index].setValue(formValue);
    this.locationForms[index].markAsPristine();
    this.locationForms[index].markAsUntouched();
    this.cdr.detectChanges();
  }

  confirmClicked(index: number) {
    this.loading.set(index, true);
    const originalLocations = this.originalResponse[index].shopifyWarehouse;
    const newLocations = this.locationForms[index].value.assignedLocations;
    const netsuiteWarehouseId = this.originalResponse[index].netsuiteWarehouseId;
    const warehouseName = this.originalResponse[index].netsuiteWarehouseName;
  
    const locationsToAdd = newLocations.filter((newLocation: string) =>
      !originalLocations.some((originalLocation: ShopifyWarehouse) => originalLocation.shopifyDomainName === newLocation)
    );
  
    const locationsToRemove = originalLocations.filter((originalLocation: ShopifyWarehouse) =>
      !newLocations.some((newLocation: string) => newLocation === originalLocation.shopifyDomainName)
    );
  
    const observables: Observable<BaseResponse>[] = [
      ...locationsToAdd.map((domain: string) => 
        this.locationMappingService.addDomainToLocation(
          netsuiteWarehouseId,
          domain,
          warehouseName
        )
      ),
      ...locationsToRemove.map((location: ShopifyWarehouse) => 
        this.locationMappingService.removeDomainFromLocation(
          netsuiteWarehouseId,
          location.shopifyDomainName,
          location.shopifyWarehouseId,
          warehouseName
        )
      )
    ];
  
    forkJoin(observables).subscribe({
      next: () => {
        this.notifService.spawnAlert('Locations updated successfully');
        this.locationForms[index].markAsPristine();
        this.getLocationMappings();
        this.loading.set(index, false);
      },
      error: () => {
        this.notifService.spawnError('An error occurred while updating locations');
        this.loading.set(index, false);
      },
    });
  }
}