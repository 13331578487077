import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[appPdfLinkClickDirective]',
  standalone: true
})
export class PdfLinkClickDirective implements AfterViewInit, OnDestroy {
  @Output() linkClick = new EventEmitter<string>();
  private observer!: MutationObserver;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.addClickListeners();
    
    this.observer = new MutationObserver(() => this.addClickListeners());
    this.observer.observe(this.el.nativeElement, { childList: true, subtree: true });
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private addClickListeners() {
    const links = this.el.nativeElement.querySelectorAll('a');
    links.forEach((link: any) => {
      link.addEventListener('click', this.handleLinkClick);
    });
  }

  private handleLinkClick = (event: Event) => {
    event.preventDefault();
    const link = event.target as HTMLAnchorElement;
    this.linkClick.emit(link.href);
  }
}