<mat-card class="h-full">
    <mat-card-header class="flex flex-row justify-center align-middle text-center">
        <mat-card-title>Most Common Prompts</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-[90%] h-full">
        @if(isLoading){
            <div class="flex flex-row justify-center align-center w-full h-full">
                <mat-spinner></mat-spinner>
            </div>
        }
        @else {
            <ngx-charts-tree-map
            [gradient]="false"
            [animations]="true"
            [results]="results"
            >
            </ngx-charts-tree-map>
        }
    </mat-card-content>
</mat-card>
