import { Component } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { LoadingLogoComponent } from '../loading-logo/loading-logo.component';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrl: './loading.component.scss',
    standalone: true,
    imports: [LoadingLogoComponent, AsyncPipe]
})
export class LoadingComponent {

  loading$ = this.loadingService.loading$;

  constructor(private loadingService: LoadingService) { }

}
