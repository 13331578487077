import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CSAIAudit } from '../../models/csai-audit';
import { CsaiHistoryService } from '../../services/csai-history.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatActionList, MatListItem } from '@angular/material/list';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-csai-history',
    templateUrl: './csai-history.component.html',
    styleUrl: './csai-history.component.scss',
    standalone: true,
    imports: [MatProgressSpinner, MatFormField, MatLabel, MatInput, ReactiveFormsModule, MatActionList, MatListItem, MatTooltip, MatIcon, DatePipe]
})
export class CsaiHistoryComponent implements OnInit{
  
  loadingHistory = true;
  searchForm = new FormControl('');

  @Output() historicalPromptSelected = new EventEmitter<CSAIAudit>();

  constructor(private csaiHistoryService: CsaiHistoryService) {}

  ngOnInit(): void {
    this.csaiHistoryService.getAudits(false).subscribe(() => {
      this.loadingHistory = false;
    });
  }

  get filteredAudits(){
    return this.csaiAudits.filter(x => x.prompt.toLowerCase().includes(this.searchForm?.value?.toLowerCase() ?? '') || x.response.toLowerCase().includes(this.searchForm?.value?.toLowerCase() ?? ''));
  }

  get csaiAudits(){
    return this.csaiHistoryService.csaiAudits;
  }

  getHistoricalPrompt(prompt: string){
    if(prompt.length > 30){
      return prompt.substring(0, 30) + '...';
    }
    return prompt;
  }

  selected(audit: CSAIAudit){
    this.historicalPromptSelected.emit(audit);
  }


}
