import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuditLogService } from '../../services/audit-log.service';
import { AuditLog } from '../../models/audit-log';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime} from 'rxjs';
import { MatSort, MatSortable, MatSortHeader } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuditDetailsComponent } from '../audit-details/audit-details.component';
import { MatIcon } from '@angular/material/icon';
import { NgClass, DatePipe } from '@angular/common';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-audit-table',
    templateUrl: './audit-table.component.html',
    styleUrl: './audit-table.component.scss',
    animations: [
        trigger('detailExpand', [
            state('collapsed,void', style({ height: '0', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [MatSort, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatIconButton, MatIcon, MatSortHeader, NgClass, AuditDetailsComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, DatePipe]
})
export class AuditTableComponent implements OnInit, AfterViewInit, AfterContentChecked{

  isLoadingData = false;
  auditLogs: AuditLog[] = [];
  dataSource: MatTableDataSource<AuditLog> = new MatTableDataSource<AuditLog>();
  displayedColumns: string[] = ['expand', 'id', 'modifyStartTimeUtc','modifyCompletionTimeUtc', 'database', 'message', 'success']
  selectedRow: AuditLog | null = null;

  @Input() integrationForm!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChildren(AuditDetailsComponent) details!: QueryList<AuditDetailsComponent>;
  
  constructor(private auditLogService: AuditLogService, private cdr: ChangeDetectorRef) { }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
  
  ngAfterViewInit(): void {
    this.sort.sort(({ id: 'modifyCompletionTimeUtc', start: 'desc'}) as MatSortable);
    this.cdr.detectChanges();
    this.sort.sortChange.subscribe(() => {
        this.getAuditLogs();
        this.paginator.firstPage();
      });
    this.getAuditLogs();
  }

  ngOnInit(): void {
    this.integrationForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.paginator.firstPage();
      this.getAuditLogs();
    });
  }

  paged(_: any){
    this.getNextPageOfAuditLogs(); 
  }

  getNextPageOfAuditLogs(){
    const pageMultiplier = this.paginator.pageIndex * this.paginator.pageSize;
    const pageSize = this.paginator.pageSize;
    this.auditLogService.getAuditLogs(
      this.integrationForm.get('startDate')?.value, 
      this.integrationForm.get('endDate')?.value,
      this.integrationForm.get('modelTypeSelection')?.value,
      this.integrationForm.get('integrationTypeSelection')?.value,
      this._parseSuccess(this.integrationForm.get('successSelection')?.value),
      this.integrationForm.get('idSelection')?.value,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction as 'asc' | 'desc'
    ).subscribe((data) => {
      for(let i = pageMultiplier; i < pageMultiplier + pageSize; i++){
        if(data.auditLogs[i - pageMultiplier] === undefined){
          break;
        }
        this.auditLogs[i] = data.auditLogs[i - pageMultiplier];
      }
      this.dataSource = new MatTableDataSource(this.auditLogs);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortData(this.dataSource.data, this.sort);
      this.cdr.detectChanges();
    });
  }

  getAuditLogs(){
    this.isLoadingData = true;
    this.auditLogService.getAuditLogs(
      this.integrationForm.get('startDate')?.value, 
      this.integrationForm.get('endDate')?.value,
      this.integrationForm.get('modelTypeSelection')?.value,
      this.integrationForm.get('integrationTypeSelection')?.value,
      this._parseSuccess(this.integrationForm.get('successSelection')?.value),
      this.integrationForm.get('idSelection')?.value,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.sort.active,
      this.sort.direction as 'asc' | 'desc')
      .subscribe((data) => {
        this.auditLogs = new Array<AuditLog>(data.totalCount);
        for(let i = 0; i < data.totalCount; i++){
          this.auditLogs[i] = data.auditLogs[i];
        }
        this.dataSource = new MatTableDataSource(this.auditLogs);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortData(this.dataSource.data, this.sort);
        this.isLoadingData = false;
    });

  }

  rowClicked(row: AuditLog){
    if(this.selectedRow === row){
      this.selectedRow = null;
      return;
    }
    this.selectedRow = row;
    const index = this.auditLogs.indexOf(row) - (this.paginator.pageIndex * this.paginator.pageSize);
    this.details.toArray()[index].loadData();
  }

  private _parseSuccess(succ: string | null | undefined){
    if(succ === null || succ === undefined || succ === ''){
      return null;
    }
    return succ.toLowerCase() === 'true' ? true : succ.toLowerCase() === 'false' ? false : null;
  }

}
