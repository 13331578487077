<div class="flex flex-col">
    <div class="flex flex-row">
        <button mat-icon-button (click)="feedback('Bad')" [disabled]="feedbackProvided || providingFeedback" class="m-4" matTooltip="Bad">
            <mat-icon class="{{(feedbackProvided || providingFeedback ? 'text-slate-600' : 'text-slate-50')}}">thumb_down</mat-icon>
        </button>
        <button mat-icon-button (click)="feedback('Neutral')" [disabled]="feedbackProvided || providingFeedback" class="m-4" matTooltip="Neutral">
            <mat-icon class="{{(feedbackProvided || providingFeedback ? 'text-slate-600' : 'text-slate-50')}}">thumbs_up_down</mat-icon>
        </button>
        <button mat-icon-button (click)="feedback('Good')" [disabled]="feedbackProvided || providingFeedback"  class="m-4" matTooltip="Good">
            <mat-icon class="{{(feedbackProvided || providingFeedback ? 'text-slate-600' : 'text-slate-50')}}">thumb_up</mat-icon>
        </button>
    </div>
    @if(providingFeedback){
    <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    }
</div>
