<div class="main-gradient w-full h-full overflow-y-hidden flex flex-col items-center">
    <div class="w-[50%] flex flex-col mt-5" [formGroup]="form">
        <mat-form-field>
            <mat-label>Team</mat-label>
            <mat-select formControlName="approvingTeamId">
                @for(team of teams; track $index){
                    <mat-option [value]="team.name">{{team.name}}</mat-option>                
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field (click) = "$event.stopPropagation()">
            <mat-label>Sku</mat-label>
            <input matInput type="text" formControlName="sku" placeholder="What is the SKU of the item we need data for?">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Request</mat-label>
            <textarea matInput formControlName="request" placeholder="In detail, please list the resources / features needed. (Ex: If the listing needs updating, or needs correcting.)"></textarea>
        </mat-form-field>
        <button mat-raised-button color="accent" (click)="makeRequest()" disabled="{{submitDisabled}}">
            @if(loading){
                <mat-spinner diameter="20"></mat-spinner>
            } @else {
                Submit
            }
        </button>
    </div>
</div>