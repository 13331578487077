<button mat-icon-button class="mr-2" [matMenuTriggerFor]="menu" (click)="openMenu()"><mat-icon matBadge="{{unreadNotificationCount}}" matBadgePosition="after" matBadgeColor="accent" class="text-slate-200">layers</mat-icon></button>
<mat-menu #menu="matMenu">
    @if(callbackNotificationCount > 0){
        <div class="flex flex-col">
            @for(callback of callbacks; track $index){
                <div class="p-4">
                    <div class="flex flex-row text-center justify-center align-middle">
                        @if(callback.loading){
                            <div class="pt-3">
                                <mat-spinner [diameter]="25"></mat-spinner>
                            </div>
                        }
                        @else if(!callback.loading && callback.success){
                            <div class="pt-3">
                                <mat-icon>check_circle</mat-icon>
                            </div>
                        }
                        @else if(!callback.loading && !callback.success){
                            <div class="pt-3">
                                <mat-icon>error</mat-icon>
                            </div>
                        }
                        <div class="pl-4 pr-4" class="pt-3">
                            {{callback.name}}
                        </div>
                        <button mat-icon-button (click)="delete(callback.id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            }
            
        </div>
    }
    @else {
        <div class="p-4"> No Notifications</div>
    }    
</mat-menu>