import { Component, Inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Roles } from './constants';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { Subject, filter, takeUntil } from 'rxjs';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { RoleService } from './services/role.service';
import { LoadingComponent } from './components/loading/loading.component';
import { MatActionList, MatListItem } from '@angular/material/list';
import { MatDrawerContainer, MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { PowerToolbarComponent } from './components/power-toolbar/power-toolbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    standalone: true,
    imports: [PowerToolbarComponent, MatDrawerContainer, MatDrawer, MatActionList, MatListItem, MatDrawerContent, LoadingComponent, RouterOutlet]
})
export class AppComponent implements OnInit{
  title = 'zline-power';
  loginDisplay = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router, 
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService, 
    private roleService: RoleService
  ){}
  
  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();

    this._setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe(() => {
      if (this.authService.instance.getAllAccounts().length === 0) {
        window.location.pathname = "/";
      } else {
        this._setLoginDisplay();
      }
    });
  
    this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)).subscribe(() => {
      this.router.navigate(['/csai'])
    });

    this.msalBroadcastService.msalSubject$.subscribe((result: EventMessage) => {
      if(result.eventType === EventType.ACQUIRE_TOKEN_FAILURE && result.interactionType === 'silent'){
        //todo - popup and ask if they would like to re-sign in instead of just redirecting.
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      }
    })
    
  this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this._setLoginDisplay();
      this._checkAndSetActiveAccount();
    });
  }

  private _setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay){
      this.roleService.refreshRoles().subscribe();
    }
  }

  private _checkAndSetActiveAccount(){
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  routeTo(route: string){
    this.router.navigate([route]);
  }

  get canAccessCSAIAdminPage(){
    return this.roleService.roles?.some(x => [Roles.Admin, Roles.CSAIAdmin].includes(x));
  }
  get canAccessIntegrationReportingPage(){
    return this.roleService.roles?.some(x => [Roles.Admin, Roles.ReportingViewer].includes(x));
  }
  get canAccessUserAdminPage(){
    return this.roleService.roles?.some(x => [Roles.Admin].includes(x));
  } 
  get canAccessLocationManagementPage(){
    return this.roleService.roles?.some(x => [Roles.Admin, Roles.LocationManager].includes(x));
  }
  get canAccessWikiApprovalPage() {
    return this.roleService.roles?.some(x => [Roles.Admin, Roles.WikiRequestApprover].includes(x));
  }
}
