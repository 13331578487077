<div class="main-gradient w-full h-full overflow-y-hidden flex flex-col items-center">
    <div class="w-[50%] flex flex-col mt-5" [formGroup]="form">
        <mat-form-field>
            <mat-label>Team</mat-label>
            <mat-select formControlName="approvingTeamId">
                <mat-select-trigger>
                    {{selectedTeamName}}
                </mat-select-trigger>
                @for(team of teams; track $index){
                    <mat-option [value]="team.id">{{team.name}}<div class="italic text-sm">{{team.approverEmail}}</div></mat-option>                
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field (click) = "$event.stopPropagation()">
            <mat-label>Subject</mat-label>
            <input matInput type="text" formControlName="subject" placeholder="Wiki Request Subject">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Request</mat-label>
            <textarea matInput formControlName="request" placeholder="In detail, please list the resources / features needed. (Ex: If the listing needs updating, or needs correcting.)"></textarea>
        </mat-form-field>
        <input type="file" class="hidden" (change)="onFileSelected($event)" #fileInput>
        <button class="mb-5" mat-raised-button color="accent" (click)="fileInput.click()" disabled="{{loading}}">Add File <mat-icon>attach_file</mat-icon></button>
        <div class="flex flex-row items-center mb-5">
            @for (file of files; track $index) {
                <div class="flex flex-row items-center">
                    <mat-icon class="text-slate-200">attach_file</mat-icon>
                    <p class="ml-2 text-slate-200">{{file.name}}</p>
                    <button mat-icon-button (click)="removeFile($index)" disabled="{{loading}}">
                        <mat-icon class="text-red-700">close</mat-icon>
                    </button>
                </div>
            }
        </div>
        <button mat-raised-button color="accent" (click)="makeRequest()" disabled="{{submitDisabled}}">
            @if(loading){
                <mat-spinner diameter="20"></mat-spinner>
            } @else {
                Submit
            }
        </button>
    </div>
</div>