<h2 mat-dialog-title class="text-center">Upload New Pdf</h2>
<mat-dialog-content class="flex flex-row justify-center items-center w-full">
    <input type="file" class="hidden" (change)="onFileSelected($event)" #fileInput>
    <button class="w-full" mat-raised-button color="primary" (click)="fileInput.click()">Select File <mat-icon>attach_file</mat-icon> </button>
    <mat-form-field [formGroup]="form" class="w-full mt-2">
        <mat-label>Most Relevant Sku</mat-label>
        <input formControlName="sku" matInput placeholder="RBIV-30">
    </mat-form-field>
    <mat-form-field [formGroup]="form" class="w-full mt-2">
        <mat-label>Roles</mat-label>
        <mat-select formControlName="role" multiple>
            @for (role of roles; track $index) {
                <mat-option [value]="role">{{role}}</mat-option>
            }
        </mat-select>
        <mat-hint>If None are selected, it will be available to everyone</mat-hint>
    </mat-form-field>
    <div class="flex flex-row">
        @for (file of files; track $index) {
            <div class="flex flex-col">
                <button mat-icon-button (click)="removeFile(file)" matTooltip="Remove Pdf" matTooltipShowDelay="500"><mat-icon>insert_drive_file</mat-icon> </button>
                <div>{{file.name}}</div>
            </div>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="w-[48.5%]" mat-raised-button color="warn" (click)="cancel()" cdkFocusInitial>Cancel</button>
    <button class="w-[48.5%]" mat-raised-button [disabled]="confirmButtonDisabled" (click)="onConfirm()">Upload</button>
</mat-dialog-actions>
