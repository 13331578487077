import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Map, NavigationControl, RequestTransformFunction } from 'maplibre-gl';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-display-map',
  standalone: true,
  imports: [],
  templateUrl: './display-map.component.html',
  styleUrl: './display-map.component.scss'
})
export class DisplayMapComponent implements OnInit {
  
  map: Map | undefined;

  constructor(private msalService: MsalService) {}

  @ViewChild('map') mapContainer!: ElementRef<HTMLElement>;
  
  token: string | null = null;

  async getCachedToken() {
    if(!this.token || this._getClaimsFromToken(this.token).exp < Date.now() / 1000) {
      this.token = (await this.msalService.instance.acquireTokenSilent({
        scopes: [...environment.apiConfig.scopes],
      })).accessToken;
    }
  }

  ngOnInit(): void {
    this.createNewMap().then().catch();
  }
  
  async createNewMap() {
    const transformer = await this.getMapRequestTransformerForAuth();

    this.map = new Map({
      style: environment.apiConfig.mapServiceUri,
      zoom: 12,
      center: {
        lng: -119.8143,
        lat: 39.5299,
      },
      container: this.mapContainer.nativeElement,
      transformRequest: transformer,
      
    });
    this.map.addControl(new NavigationControl(), 'top-right');
  }

  async getMapRequestTransformerForAuth(): Promise<RequestTransformFunction> {
    await this.getCachedToken();
    return (url: string) => {
      return {
        url: url,
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
    }
  }


  private _getClaimsFromToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }

}
