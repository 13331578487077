import { Injectable } from '@angular/core';
import { LoadingCallback } from '../models/loading-callback';

@Injectable({
  providedIn: 'root'
})
export class LoadingCallbackService {

  loadingCallbacks: LoadingCallback[] = [];

  addCallback(name: string, id: string, callback: () => void | null){
    this.loadingCallbacks.push({id, name, loading: true, viewed: false, callback: callback});
  }

  expireCallback(id: string, success: boolean){
    const callback = this.loadingCallbacks.find((cb) => cb.id === id);
    if(callback){
      callback.loading = false;
      callback.success = success;
      if(callback.callback){
        callback.callback();
      }
    }
  }

  viewAll(){
    this.loadingCallbacks.forEach(x => x.viewed = true);
  }
  remove(id: string){
    this.loadingCallbacks = this.loadingCallbacks.filter(x => x.id !== id);
  }
}
