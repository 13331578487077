import { Component } from '@angular/core';
import { ShowroomsTableComponent } from '../showrooms-table/showrooms-table.component';
import { MatFormField, MatInput, MatLabel } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-showrooms',
  standalone: true,
  imports: [MatInput, MatFormField, MatLabel, ReactiveFormsModule, ShowroomsTableComponent],
  templateUrl: './showrooms.component.html',
  styleUrl: './showrooms.component.scss'
})
export class ShowroomsComponent {

}
