import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/input";
import { LocationMappingTableComponent } from "../location-mapping-table/location-mapping-table.component";

@Component({
    selector: 'app-location-mapping',
    templateUrl: './location-mapping.component.html',
    styleUrl: './location-mapping.component.scss',
    standalone: true,
    imports: [MatInput, MatFormField, MatLabel, ReactiveFormsModule, LocationMappingTableComponent]
})

export class LocationMappingComponent {

    form: FormGroup = new FormGroup({
        search: new FormControl('')
    });

    get searchControl(): FormControl{
        return this.form.get('search') as FormControl;
    }
}