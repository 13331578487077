import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-add-showroom',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    ReactiveFormsModule
  ],
  templateUrl: './add-showroom.component.html',
  styleUrl: './add-showroom.component.scss'
})
export class AddShowroomComponent implements OnInit {
  showroomForm!: FormGroup;

  formFields = [
    { name: 'name', label: 'Name', required: true },
    { name: 'phone', label: 'Phone', required: false },
    { name: 'email', label: 'Email', required: false },
    { name: 'website', label: 'Website', required: false },
    { name: 'address', label: 'Address', required: true },
    { name: 'city', label: 'City', required: true },
    { name: 'state', label: 'State', required: true },
    { name: 'postcode', label: 'Postcode', required: true },
    { name: 'country', label: 'Country', required: true },
  ];
  displayedColumns = ['label', 'input'];

  constructor(
    public dialogRef: MatDialogRef<AddShowroomComponent>,
    private fb: FormBuilder 
  ) {}

  ngOnInit(): void {
    this.showroomForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postcode: ['', Validators.required],
      country: ['', Validators.required],
      phone: [''],
      email: [''],
      website: [''],
    });
  }

  onSubmit(): void {
    if (this.showroomForm.valid) {
      this.dialogRef.close(this.showroomForm.value);
    }
  }
}