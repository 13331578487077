import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PromptResponse } from '../models/prompt-response';
import { environment } from './../../environments/environment';
import { DocumentData } from '../models/document-data';

@Injectable({
  providedIn: 'root'
})
export class CsaiService {

  constructor(private httpClient: HttpClient) { }

  prompt(prompt: string): Observable<PromptResponse>{
    return this.httpClient.get<PromptResponse>(`${environment.apiConfig.uri}api/v1/csai/prompt?prompt=${prompt}`);
  }
  
  getDocuments(): Observable<DocumentData[]>{
    return this.httpClient.get<DocumentData[]>(`${environment.apiConfig.uri}api/v1/csai`);
  }

  getPdfLink(id: number): Observable<string>{
    return this.httpClient.get(`${environment.apiConfig.uri}api/v1/csai/pdf?id=${id}`, {responseType: 'text'});
  }

  getPdfLinkByName(pdfName: string): Observable<string>{
    return this.httpClient.get(`${environment.apiConfig.uri}api/v1/csai/pdfByName?pdfName=${pdfName}`, {responseType: 'text'});
  }

  deletePdf(ids: number[]): Observable<any>{
    return this.httpClient.delete(`${environment.apiConfig.uri}api/v1/csai`, {body: ids});
  }

  uploadPdf(file: File, sku: string | null, roles: string[] | null): Observable<any>{
    const formData = new FormData();
    formData.append('formFile', file);
    if(sku !== null){
      formData.append('sku', sku);
    }
    if(roles !== null){
      roles.forEach((role) => formData.append('roles', role));
    }
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/csai`, formData);
  }
  
  reportIssue(promptId: string, issue: string): Observable<any>{
    return this.httpClient.post(`${environment.apiConfig.uri}api/v1/csai/issue`, {promptId: promptId, issue: issue});
  }

  provideFeedback(promptId: string, feedback: string): Observable<any>{
    return this.httpClient.put(`${environment.apiConfig.uri}api/v1/csai/feedback`, {promptId: promptId, feedbackValue: feedback});
  }
  getRoles(): Observable<string[]>{
    return this.httpClient.get<string[]>(`${environment.apiConfig.uri}api/v1/csai/roles`);
  }
}
