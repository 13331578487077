<div class="w-full h-full">
  @if(loginDisplay){
    <app-power-toolbar (drawerToggled)="drawer.toggle()"></app-power-toolbar>
  }
  <mat-drawer-container [hasBackdrop]="true" class="overflow-y-hidden h-full">
    <mat-drawer #drawer [mode]="'side'" class="bg-slate-950 min-w-[250px]">
      <div class="h-full main-gradient flex flex-col text-2xl  text-center align-middle overflow-auto">
        <mat-action-list>
          <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('csai')">
            <div  class="text-slate-200 text-center font-medium">
              CSAI
            </div>
          </button>
          <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('map')">
            <div  class="text-slate-200 text-center font-medium">
              Map
            </div>
          </button>
          @if(canAccessCSAIAdminPage){
            <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('csai-admin')">
              <div  class="text-slate-200 text-center font-medium ">
                CSAI Admin
              </div>
            </button>
          }
          @if(canAccessIntegrationReportingPage){
            <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('integration-reporting')">
              <div  class="text-slate-200 text-center font-medium ">
                Integration Reporting
              </div>
            </button>
          }
          @if(canAccessUserAdminPage){
            <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('user-admin')">
              <div  class="text-slate-200 text-center font-medium">
                User Admin
              </div>
            </button>
          }
          <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('csai-reporting')">
            <div  class="text-slate-200 text-center font-medium">
              CSAI Reporting
            </div>
          </button>
          @if(canAccessLocationManagementPage){
          <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('location-mapping')">
            <div  class="text-slate-200 text-center font-medium">
              Location Mapping
            </div>
            </button>
          }
          @if(canAccessLocationManagementPage){
          <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('showrooms')">
            <div class="text-slate-200 text-center font-medium">
              Manage Showrooms
            </div>
          </button>
          }
          <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('wiki-request')">
            <div class="text-slate-200 text-center font-medium">
              Wiki Request
            </div>
          </button>
          @if(canAccessWikiApprovalPage){
            <button mat-list-item class="h-8 min-h-24 mb-1" color="accent" (click)="routeTo('wiki-request-approval')">
              <div class="text-slate-200 text-center font-medium">
                Manage Wiki Requests
              </div>
            </button>
          }
        </mat-action-list>
      </div>
    </mat-drawer>
    <mat-drawer-content>  
      <app-loading></app-loading>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>