import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-showroom-csv',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    FormsModule
  ],
  templateUrl: './showroom-csv.component.html',
  styleUrl: './showroom-csv.component.scss'
})
export class ShowroomCsvComponent {
  selectedFile: File | null = null;

  constructor(
    public dialogRef: MatDialogRef<ShowroomCsvComponent>
  ) {}

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
  }
}