import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { PendingApprovalRequest } from '../../models/pending-approval-request';
import { WikiService } from '../../services/wiki.service';
import { MatInputModule } from '@angular/material/input';
import { AppNotificationService } from '../../services/app-notification.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-wiki-request-review-table',
  standalone: true,
  imports: [MatTable, MatFormFieldModule, DatePipe, MatInputModule, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFormField, ReactiveFormsModule, MatLabel, MatSelect, MatOption, MatProgressSpinner, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow],
  templateUrl: './wiki-request-review-table.component.html',
  styleUrl: './wiki-request-review-table.component.scss'
})
export class WikiRequestReviewTableComponent implements OnInit {
  dataSource: MatTableDataSource<PendingApprovalRequest> = new MatTableDataSource<PendingApprovalRequest>();

  loading = false;
  none = false;

  forms: FormGroup[] = [];

  confirmLoading = new Map<number, boolean>();

  displayedColumns: string[] = ['requesterEmail', 'requesterName', 'subject', 'requestDetails', 'requestedOn', 'files', 'additionalNotes', 'actions'];

  constructor(private wikiService: WikiService, private appNotificationService: AppNotificationService) { }

  ngOnInit(): void {
    this.getPendingRequests();
  }

  getPendingRequests() {
    this.loading = true;
    this.wikiService.getApprovableWikiRequests().subscribe((requests) => {
      this.dataSource.data = requests;
      this.forms = [];

      requests.forEach(() => {
        this.forms.push(new FormGroup({
          additionalNotes: new FormControl('')
        }));
      });

      if(requests.length === 0){
        this.none = true;
      } else {
        this.none = false;
      }

      this.loading = false;
    });
  }

  denyClicked(index: number){
    this.acceptOrDenyRequest(index, this.dataSource.data[index].id, false, this.forms[index].get('additionalNotes')?.value);
  }

  approveClicked(index: number){
    this.acceptOrDenyRequest(index, this.dataSource.data[index].id, true, this.forms[index].get('additionalNotes')?.value);
  }

  acceptOrDenyRequest(index: number, id: number, approved: boolean, additionalNotes: string) {
    this.confirmLoading.set(index, true);
    this.wikiService.approveWikiRequest(approved, additionalNotes, id).subscribe(() => {
      this.confirmLoading.set(index, false);
      this.getPendingRequests();
      this.appNotificationService.spawnAlert(`Request ${approved ? 'approved' : 'denied'} successfully`);
    });
  }

  downloadFile(index: number, fileIndex: number){
    const link = document.createElement('a');
    link.href = this.dataSource.data[index].fileSasLinks[fileIndex];
    link.download = this.dataSource.data[index].fileSasLinks[fileIndex];
    link.click();
  }
}
