import { Component, OnInit } from '@angular/core';
import { WikiService } from '../../services/wiki.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppNotificationService } from '../../services/app-notification.service';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { ApprovingTeam } from '../../models/approving-team';

@Component({
  selector: 'app-wiki-requests',
  standalone: true,
  imports: [MatCard, MatCardHeader, MatCardTitle, MatSelectTrigger, MatIconButton, MatTooltip, MatIcon, MatCardContent, MatProgressSpinner, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatButton, MatSelect, MatOption],
  templateUrl: './wiki-requests.component.html',
  styleUrl: './wiki-requests.component.scss'
})
export class WikiRequestsComponent implements OnInit {

  files: File[] = [];
  teams: ApprovingTeam[] = []
  form!: FormGroup;
  loading = false;

  constructor(private wikiService: WikiService, private formBuilder: FormBuilder, private appNotificationService: AppNotificationService) {}

  ngOnInit(): void {
    this.getApprovingTeams();
    this.form = this.formBuilder.group({
      approvingTeamId: ['', Validators.required],
      subject: ['', Validators.required],
      request: ['', Validators.required]
    });
  }

  getApprovingTeams() {
    this.wikiService.getApprovingTeams().subscribe((teams) => {
      this.teams = teams;
    });
  }

  makeRequest(): void {
    this.loading = true;
    this.wikiService.makeWikiRequest(this.files, this.form.get('approvingTeamId')?.value, this.form.get('subject')?.value,this.form.get('request')?.value).subscribe({
      next: () => {

      this.appNotificationService.spawnAlert('Request submitted successfully');

      this.form.reset();
      this.files = [];

      this.loading = false;
    },
    error: () => {
      this.appNotificationService.spawnError('Failed to submit request. Contact your system administrator.');
      this.loading = false;
    }});
  }

  onFileSelected($event: any){
    this.files.push($event.target.files[0]);
  }

  removeFile(index: number){
    this.files.splice(index, 1);
  }

  get selectedTeamName(): string {
    return this.teams.find(x => x.id == this.form.get('approvingTeamId')?.value)?.name ?? '';
  }
  
  get submitDisabled(): boolean {
    return this.form.invalid || this.loading;
  } 


}
