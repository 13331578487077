import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CsaiService } from '../../services/csai.service';
import { PdfDownloadService } from '../../services/pdf-download.service';
import { TypewriterService } from '../../services/typewriter.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportIssueComponent } from '../report-issue/report-issue.component';
import { CsaiHistoryService } from '../../services/csai-history.service';
import { CSAIAudit } from '../../models/csai-audit';
import { forkJoin } from 'rxjs';
import { SpeechService } from '../../services/speech.service';
import { SpeechSettingComponent } from '../speech-setting/speech-setting.component';
import { AppNotificationService } from '../../services/app-notification.service';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { LoadingLogoComponent } from '../loading-logo/loading-logo.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MarkdownComponent } from 'ngx-markdown';
import { CsaiHistoryComponent } from '../csai-history/csai-history.component';
import { MatDrawerContainer, MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { ExampleQuestionGeneratorComponent } from '../example-question-generator/example-question-generator.component';
import { NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton, MatButton } from '@angular/material/button';
import { CsaiFeedbackComponent } from '../csai-feedback/csai-feedback.component';
import { PdfLinkClickDirective } from '../../directives/pdf-link-click-directive.directive';
import { YoutubeEmbedComponent } from '../youtube-embed/youtube-embed.component';
import { GoogleSheetEmbedComponent } from '../google-sheet-embed/google-sheet-embed.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-csai',
    templateUrl: './csai.component.html',
    styleUrl: './csai.component.scss',
    standalone: true,
    imports: [PdfLinkClickDirective, MatIconButton, MatTooltip, MatIcon, NgStyle, ExampleQuestionGeneratorComponent, MatMenuTrigger, MatDrawerContainer, MatDrawer, CsaiHistoryComponent, MatDrawerContent, MarkdownComponent, MatTabGroup, MatTab, NgxExtendedPdfViewerModule, MatProgressBar, LoadingLogoComponent, ReactiveFormsModule, FormsModule, MatFormField, MatLabel, MatInput, MatButton, MatMenu, MatMenuItem, CsaiFeedbackComponent]
})
export class CsaiComponent implements OnInit, OnDestroy{

  @ViewChild('promptResponseDiv') promptResponseDiv!: ElementRef;

  @ViewChild(NgxExtendedPdfViewerComponent) pdfViewer!: NgxExtendedPdfViewerComponent;

  get prDivH(): string{
    return `${this.promptResponseDiv?.nativeElement.offsetHeight/1.07}px`;
  }

  isPrompting = false;

  currentPdfTabIndex = -1;

  lastPrompt = '';
  
  voiceEnabled: boolean;

  activePromptId = '';

  lastPromptTypedForm = new FormControl('');
  promptResponseForm = new FormControl('');

  pdfSrc: Blob[] = [];

  promptInput = new FormControl('');

  constructor(
    private csaiService: CsaiService, 
    private pdfDownloadService: PdfDownloadService, 
    private typeWriterService: TypewriterService, 
    private matDialog: MatDialog, 
    private csaiHistoryService: CsaiHistoryService,
    private speechService: SpeechService,
    private appNotificationService: AppNotificationService,
    private domSanitizer: DomSanitizer
  ) {
    this.voiceEnabled = localStorage.getItem('voiceEnabled') === 'true';
  }


  ngOnInit(): void {
    this.promptInput.valueChanges.subscribe();
  }

  ngOnDestroy(): void {
    this.speechService.cancelCurrentSpeaking();
  }

  submit(){
    if(this.promptInput.value && this.promptInput.value.length > 0){

      this.lastPrompt = this.promptInput.value!;
      this.lastPromptTypedForm.setValue('');

      this.typeWriterService.startTypeWriter(crypto.randomUUID() ,this.lastPrompt, this.lastPromptTypedForm, 50);

      this.promptResponseForm.setValue('');
      this.pdfSrc = [];

      this.isPrompting = true;
      this.promptInput.disable();
      
      this.csaiService.prompt(this.promptInput.value).subscribe({
        next: (res) => {
          this.csaiHistoryService.getAudits(true).subscribe();

          this.activePromptId = res.promptId;

          this.typeWriterService.startTypeWriter(crypto.randomUUID(), res.response.split('ASSISTANT')[1], this.promptResponseForm, 10);
  
          if(this.voiceEnabled){
            this.speechService.makeSpeakingRequest(res.response.split('ASSISTANT')[1]);
          }
          res.pdfLinks.forEach((pdfLink, index) => {
            this.pdfDownloadService.downloadPdf(pdfLink).subscribe((pdf) => {
              this.pdfSrc[index] = pdf;
            });
          });
        },
        error: () => {
          this.appNotificationService.spawnError('An Error Has Occured, Please Try Again. If the issue persists, please report it.');
          this.isPrompting = false;
          this.promptInput.enable();
        },
        complete: () => {
          this.isPrompting = false;
          this.promptInput.enable();
      }});
      
      this.promptInput.setValue('');

    }
  }

  tabChanged($event: any){
    this.currentPdfTabIndex = $event.index;
  }


  reportIssuePressed(){
    this.matDialog.open(ReportIssueComponent, {
      width: '40%',
      height: '30%'
    });
  }

  speechSettingsPressed(){
    this.matDialog.open(SpeechSettingComponent, {
      width: '40%',
      height: '30%'
    });
  }

  onLinkClick(href: any) {
    const strHref = href as string;

    if(strHref.startsWith('https://www.youtube.com/watch?v=')){
      this.matDialog.open(YoutubeEmbedComponent, {
        width: '80vw',
        height: '80%',
        minWidth: '80vw',
        minHeight: '80%',
        data: href
      });
    } else if (strHref.startsWith('https://docs.google.com/spreadsheets/d/')){
        this.matDialog.open(GoogleSheetEmbedComponent, {
          width: '80vw',
          height: '80%',
          minWidth: '80vw',
          minHeight: '80%',
          data: this.domSanitizer.bypassSecurityTrustResourceUrl(href)
        });
    } else {
      window.open(href, '_blank');
    }
  }

  get lastPromptTyped(){
    return this.lastPromptTypedForm.value;
  }
  get promptResponse(){
    return this.promptResponseForm.value;
  }

  get submitEnabled(){
    return this.promptInput.value && this.promptInput.value.length > 0 && !this.isPrompting;
  }

  historicalPromptSelected($event: CSAIAudit){
    this.lastPrompt = $event.prompt;
    this.lastPromptTypedForm.setValue($event.prompt);

    this.activePromptId = $event.id;

    this.promptResponseForm.setValue($event.response.split('ASSISTANT')[1]);

    forkJoin($event.pdfNames.map((pdfLink) => this.csaiService.getPdfLinkByName(pdfLink)))
    .subscribe((pdfs) => {
      pdfs.forEach((pdfLink, index) => {
        this.pdfDownloadService.downloadPdf(pdfLink).subscribe((pdf) => {
          this.pdfSrc[index] = pdf;
        });
      });
    });
  }
  toggleVoice(){
    this.voiceEnabled = !this.voiceEnabled;
    localStorage.setItem('voiceEnabled', this.voiceEnabled.toString());
    if(!this.voiceEnabled){
        this.speechService.cancelCurrentSpeaking();
      }
  }
}
