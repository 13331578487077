<h2 mat-dialog-title class="text-center">Speech Settings</h2>
<mat-dialog-content class="flex flex-col justify-center items-center w-full" [formGroup]="form">
    <mat-form-field class="w-full">
        <mat-label>Voice</mat-label>
        <mat-select formControlName="voiceName">
            @for (voiceName of distinctVoiceNames; track $index) {
                <mat-option [value]="voiceName">{{voiceName}}</mat-option>           
            }
        </mat-select>
    </mat-form-field>
    <div class="w-full flex flex-row justify-between">
        <div>Rate</div>
        <div>{{rateValue}}</div>
        <mat-slider min="0" max="5" step="0.1" class="w-full">
            <input matSliderThumb value="1" formControlName="rate">
        </mat-slider>
    </div>
    <div class="w-full flex flex-row justify-between">
        <div>Pitch</div>
        <div>{{pitchValue}}</div>
        <mat-slider min="0" max="2" step="0.05" class="w-full">
            <input matSliderThumb value="1" formControlName="pitch">
        </mat-slider>
    </div>
    <div class="w-full flex flex-row justify-between">
        <div>Volume</div>
        <div>{{volumeValue}}</div>
        <mat-slider min="0" max="1" step="0.05" class="w-full">
            <input matSliderThumb value="1" formControlName="volume">
        </mat-slider>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="w-[48.5%]" mat-raised-button color="warn" (click)="cancel()" cdkFocusInitial>Cancel</button>
    <button class="w-[48.5%]" mat-raised-button [disabled]="confirmButtonDisabled" (click)="onConfirm()">Confirm</button>
</mat-dialog-actions>
