import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Direction } from '../../models/direction';
import { Point } from '../../models/point';
import { AppNotificationService } from '../../services/app-notification.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Game } from '../../models/game';
import { NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-snake',
    templateUrl: './snake.component.html',
    styleUrl: './snake.component.scss',
    standalone: true,
    imports: [MatIconButton, MatIcon, MatMenuTrigger, MatMenu, MatFormField, MatInput, NgxMatColorPickerModule, ReactiveFormsModule, MatSuffix]
})
export class SnakeComponent implements OnInit, Game{

  @ViewChild('gameCanvas', { static: true }) gameCanvas!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private gameInterval!: any;
  private direction: Direction = Direction.Right;
  private snake: Point[] = [{ x: 20, y: 20 }];
  private zlineLogo?: Point;
  private readonly gridSize: number = 20;
  private logo = new Image();
  private alreadyMoved = false;
  private requestedKey: KeyboardEvent | null = null;
  colorCtr: FormControl = new FormControl('blue');
  public color: ThemePalette = 'primary';

  highScore = 0;

  gameRunning = false;

  get currentScore(){
    return this.snake.length;
  }

  start(){
    this.gameRunning = true;
    this.restart();
  }

  ngOnInit(): void {
    this.highScore = localStorage.getItem('snakeHighScore') ? parseInt(localStorage.getItem('snakeHighScore')!) : 0;
    this.ctx = this.gameCanvas.nativeElement.getContext('2d')!;
    this.logo.src = 'assets/images/ZlineLogoSmall.png';
  }

  constructor(private appNotificationService: AppNotificationService){}

  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {

    if(this.alreadyMoved) {
      this.requestedKey = event;
      return;
    }
    this.alreadyMoved = true;

    switch (event.key) {
      case 'ArrowUp':
        if (this.direction !== Direction.Down) this.direction = Direction.Up;
        break;
      case 'ArrowDown':
        if (this.direction !== Direction.Up) this.direction = Direction.Down;
        break;
      case 'ArrowLeft':
        if (this.direction !== Direction.Right) this.direction = Direction.Left;
        break;
      case 'ArrowRight':
        if (this.direction !== Direction.Left) this.direction = Direction.Right;
        break;
    }
  }

  gameLoop(){
    this._clearCanvas();
    this._moveSnake();
    this._drawSnake();
    this._drawLogo();
    this._checkCollision();
    this.alreadyMoved = false;
    if(this.requestedKey){
      this.handleKeydown(this.requestedKey);
      this.requestedKey = null;
    }
  }

  private _clearCanvas(){
    this.ctx.clearRect(0, 0, this.gameCanvas.nativeElement.width, this.gameCanvas.nativeElement.height);
  }

  private _moveSnake(){
    const head = { ...this.snake[0] };

    switch (this.direction) {
      case Direction.Up:
        head.y -= this.gridSize;
        break;
      case Direction.Down:
        head.y += this.gridSize;
        break;
      case Direction.Left:
        head.x -= this.gridSize;
        break;
      case Direction.Right:
        head.x += this.gridSize;
        break;
    }

    this.snake.unshift(head);
    if (head.x === this.zlineLogo!.x && head.y === this.zlineLogo!.y) {
      this._placeLogo();
    } else {
      this.snake.pop();
    }
  }

  private _drawSnake() {
    this.ctx.fillStyle = this.colorCtr?.value ?? 'blue';
    for (const part of this.snake) {
      this.ctx.fillRect(part.x, part.y, this.gridSize, this.gridSize);
    }
  }

  private _drawLogo() {
    this.ctx.drawImage(this.logo , this.zlineLogo!.x-(this.gridSize), this.zlineLogo!.y-(this.gridSize), this.gridSize*3, this.gridSize*3);
  }
  
  private _placeLogo(){
    const randomGrid = () => {
      let x: number, y: number;
      do {
        x = Math.floor(Math.random() * (this.gameCanvas.nativeElement.width / this.gridSize)) * this.gridSize;
        y = Math.floor(Math.random() * (this.gameCanvas.nativeElement.height / this.gridSize)) * this.gridSize;
      } while (this.snake.some(part => part.x === x && part.y === y));
      return {x, y}
    };
    this.zlineLogo = randomGrid();
  }

  private _checkCollision(){
    const head = this.snake[0];

    if (head.x < 0 || head.x >= this.gameCanvas.nativeElement.width || head.y < 0 || head.y >= this.gameCanvas.nativeElement.height) {
      this._endGame();
    }

    for (let i = 1; i < this.snake.length; i++) {
      if (head.x === this.snake[i].x && head.y === this.snake[i].y) {
        clearInterval(this.gameInterval);
        this._endGame();
      }
    } 
  }

  private _endGame(){
    this.gameRunning = false;
    clearInterval(this.gameInterval);
    this.appNotificationService.spawnError('Game Over');
    localStorage.setItem('snakeHighScore', Math.max(this.snake.length, this.highScore).toString());
    this.highScore = Math.max(this.snake.length, this.highScore);
  }

  restart(){
    this.snake = [{ x: 20, y: 20 }];
    this.direction = Direction.Right;
    this._placeLogo();
    this.gameInterval = null;
    this.gameInterval = setInterval(() => this.gameLoop(), 100);
  }
}
