import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CsaiComponent } from './components/csai/csai.component';
import { Roles } from './constants';
import { CsaiAdminComponent } from './components/csai-admin/csai-admin.component';
import { IntegrationReportingComponent } from './components/integration-reporting/integration-reporting.component';
import { UserAdminComponent } from './components/user-admin/user-admin.component';
import { CsaiReportingComponent } from './components/csai-reporting/csai-reporting.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './components/login/login.component';
import { roleGuard } from './guards/role.guard';
import { LocationMappingComponent } from './components/location-mapping/location-mapping.component';
import { ShowroomsComponent } from './components/showrooms/showrooms.component';
import { DisplayMapComponent } from './components/display-map/display-map.component';
import { WikiRequestsComponent } from './components/wiki-requests/wiki-requests.component';
import { ManageWikiRequestsComponent } from './components/manage-wiki-requests/manage-wiki-requests.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'csai',
    canActivate: [MsalGuard],
    component: CsaiComponent,
  },
  {
    path: 'map',
    canActivate: [MsalGuard],
    component: DisplayMapComponent,
  },
  {
    path: 'csai-admin',
    canActivate: [MsalGuard, roleGuard],
    component: CsaiAdminComponent,
    data: {roles: [Roles.CSAIAdmin, Roles.Admin]}
  },
  {
    path: 'integration-reporting',
    canActivate: [MsalGuard],
    component: IntegrationReportingComponent,
    data: {roles: [Roles.ReportingViewer, Roles.Admin]}
  },
  {
    path: 'user-admin',
    canActivate: [MsalGuard, roleGuard],
    component: UserAdminComponent,
    data: {roles: [Roles.Admin]}
  },
  {
    path: 'csai-reporting',
    canActivate: [MsalGuard],
    component: CsaiReportingComponent
  },
  {
    path: 'location-mapping',
    canActivate: [MsalGuard],
    component: LocationMappingComponent,
    data: {roles: [Roles.LocationManager, Roles.Admin]}
  },
  {
    path: 'showrooms',
    canActivate: [MsalGuard],
    component: ShowroomsComponent,
    data: {roles: [Roles.LocationManager, Roles.Admin]}
  },
  {
    path: 'wiki-request',
    canActivate: [MsalGuard],
    component: WikiRequestsComponent,
  },
  {
    path: 'wiki-request-approval',
    canActivate: [MsalGuard],
    component: ManageWikiRequestsComponent,
    data: {roles: [Roles.WikiRequestApprover, Roles.Admin]}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
