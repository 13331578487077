import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-example-question-generator',
    templateUrl: './example-question-generator.component.html',
    styleUrl: './example-question-generator.component.scss',
    standalone: true
})
export class ExampleQuestionGeneratorComponent implements OnInit{

  ngOnInit(): void {
    this.resetExampleQuestion();
  }

  //todo- move to a database
  exampleQuestions = [
    'What is an E0 Error?',
    'Where can I find if a Sku has been discontinued?',
    'How do I remove rust from metal?',
    'What do I do if my dishwasher leaks from one side?',
    'What do I do if my dishwasher is partially draining?',
    'What do I do if my panel is not staying attached to the dishwasher door?',
    'What do I do if the brass burner spread is not fitting correctly?',
    'What if there are loud buzzing noises coming from RCIND OR RAIND cooktops?',
    'How can shipping damage cause leaks in a dishwasher?',
    'What action should be taken if a gap is found between the bottom of the dishwasher and the side walls?',
    'What issue is associated with the current water inlet valve in older dishwasher models?',
    'Why was the change from an internal screw thread to an external screw thread made?',
    'How has the water inlet valve design changed in the new production starting January 2023?',
    'What additional accessory is mentioned for the installation of the new inlet valve?',
    'What number or code is associated with the new inlet valve installation accessory?',
    'What resource should be provided to customers needing to remove rust from metal parts?',
    'What are common causes of gas leaks according to the document?',
    'What type of issue is a gas line installation problem categorized as?',
    'What should be done if the gas company diagnoses a leak from within the cooktop?',
    'What is the main difference between a gas leak and carbon monoxide leak?',
    'Why is it important to verify whether the customer is calling about a gas leak or carbon monoxide leak?',
    'What should a customer do if their gas company or fire department has red-tagged their unit?',
    'What steps should be taken for freestanding ranges under warranty with gas leak issues?',
    'What should out-of-warranty customers do if they suspect a gas leak in their freestanding range?',
    'What is the procedure if diagnosis determines the range is causing the gas leak?',
    'What additional part must be installed on RTs and RCs during LP conversion to prevent gas leaks?',
    'When should an RC or RT unit be immediately replaced?',
    'What could cause the burner flame to be too low in a propane-only range?',
    'Why might a low burner flame not be covered under warranty?',
    'What must be used any time the gas cooktop is in use?',
    'What should be verified if the flame is uneven or wild?',
    'What should be checked if the cooktop has been converted to propane and the flame is uneven?',
    'What might cause a high flame or popping sound?',
    'What should a customer do if they have attempted to adjust the flames but still have issues after a propane conversion?',
    'What will the warranty team check during diagnosis for high flame issues?',
    'What common mistake can cause gas not to ignite?',
    'What should be verified if gas is not igniting?',
    'What should a customer try if a particular burner is not working?',
    'What might be the issue if a non-working burner can be started with a lighter or match?',
    'What could be the problem if there is no visible spark when pushing in the range knob?',
    'What might be the cause if flame height has no variation between high and low?',
    'What tool is needed for flame adjustment, and what is its SKU?',
    'What should a customer do if the flame adjustment screw is too loose?',
    'What is required for any flame adjustment service calls?',
    'What might cause orange flames even when properly adjusted?',
    'What are examples of items that could cause a change in air quality affecting flame color?'
  ];

  exampleQuestionIndex = 0;
  exampleQuestion = '';
  exampleQuestionTyped = '';

  resetExampleQuestion(){
    this.exampleQuestionIndex = 0;
    this.exampleQuestion = this.exampleQuestions[Math.floor(Math.random() * this.exampleQuestions.length)];

    this.exampleQuestionTyped = '';
    this.typeQuestion();
  }

  typeQuestion(){
    if (this.exampleQuestionIndex < this.exampleQuestion.length) {
      this.exampleQuestionTyped += this.exampleQuestion.charAt(this.exampleQuestionIndex);
      this.exampleQuestionIndex++;
      setTimeout(() => this.typeQuestion(), 50);
    }
    else{
      setTimeout(() => this.resetExampleQuestion(), 5000);
    }
  }
}
