<div class="mat-elevation-z8 w-full overflow-y-auto h-[80vh]" >
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="requesterEmail">
        <th mat-header-cell *matHeaderCellDef >Requester Email</th>
        <td mat-cell *matCellDef="let element"> {{element?.requesterEmail}} </td>
      </ng-container>

      <ng-container matColumnDef="requesterName">
        <th mat-header-cell *matHeaderCellDef> Requester Name </th>
        <td mat-cell *matCellDef="let element"> {{element?.requesterName}} </td>
      </ng-container>

      <ng-container matColumnDef="sku">
        <th mat-header-cell *matHeaderCellDef> Sku </th>
        <td mat-cell *matCellDef="let element"> {{element?.sku}} </td>
      </ng-container>

      <ng-container matColumnDef="requestDetails">
        <th mat-header-cell *matHeaderCellDef> Request Details </th>
        <td mat-cell *matCellDef="let element"> {{element?.requestDetails}} </td>
      </ng-container>

      <ng-container matColumnDef="approvalNotes">
        <th mat-header-cell *matHeaderCellDef> Additional Notes </th>
        <td mat-cell *matCellDef="let element"> {{element?.approvalNotes}} </td>
      </ng-container>

      <ng-container matColumnDef="postApprovalNotes">
        <th mat-header-cell *matHeaderCellDef>Post Approval Notes </th>
        <td mat-cell *matCellDef="let element; let i = index"> 
            <div class="flex flex-row justify-center align-middle items-center">
                <mat-form-field class="w-full" [formGroup]="forms[i]">
                    <mat-label>Post Approval Notes</mat-label>
                    <textarea matInput formControlName="postApprovalNotes" placeholder="Post Approval Notes"></textarea>
                </mat-form-field>
                <button mat-icon-button (click)="writeAdditionalNotes(i)" disabled="{{!forms[i].dirty}}"><mat-icon>send</mat-icon></button>                         
            </div>
        </td>
      </ng-container>

    <ng-container matColumnDef="closeAction">
        <th mat-header-cell *matHeaderCellDef > Close Request </th>
        <td mat-cell *matCellDef="let element; let i = index">
            @if(confirmLoading.get(i)){
                <div>
                    <mat-spinner [diameter]="24"></mat-spinner>
                </div>
            }
            @else{
                <div>
                    <button mat-icon-button matTooltip="Close" matTooltipShowDelay="500" (click)="closeRequestClicked(i)">
                        <mat-icon class="text-red-700">close</mat-icon>
                    </button>    
                </div>
            }
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    </table>

    @if(loading){
        <div class="flex justify-center">
            <mat-spinner [diameter]="48"></mat-spinner>
        </div>
    }
    @else if (none) {
        <div class="text-center text-2xl text-slate-200">
            No approved requests to show
        </div>
    }

  </div>