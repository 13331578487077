<mat-card class="h-full">
    <mat-card-header class="flex flex-row justify-center align-middle text-center">
        <mat-card-title>Prompts Over Time</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-[90%] h-full">
        @if(isLoading){
            <div class="flex flex-row justify-center align-center w-full h-full">
                <mat-spinner></mat-spinner>
            </div>
        }
        @else {
            <ngx-charts-bar-vertical
            [results]="results"
            [gradient]="false"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Date'"
            [yAxisLabel]="'Count of Prompts'"
            >
            </ngx-charts-bar-vertical>
        }        
    </mat-card-content>
</mat-card>
