<div class="main-gradient w-full h-full overflow-y-hidden flex flex-col">
    <div class="flex-none flex flex-row justify-between w-full align-middle">
        <div class="flex flex-row justify-start">
            <button mat-icon-button class="mt-2 ml-4" (click)="drawer.toggle()" matTooltip="View Historical Prompts" matTooltipShowDelay="500"><mat-icon [ngStyle]="{'color': '#CBD5E1'}">history</mat-icon></button>
            <button mat-icon-button class="mt-2 ml-4" (click)="toggleVoice()" matTooltip="Toggle Audio" matTooltipShowDelay="500">
                @if(voiceEnabled){
                    <mat-icon [ngStyle]="{'color': '#CBD5E1'}">mic</mat-icon>
                }
                @else {
                    <mat-icon [ngStyle]="{'color': '#CBD5E1'}">mic_off</mat-icon>
                }
            </button>
            <div class="ml-4 text-4xl text-slate-300 text-center py-4">
                {{lastPromptTyped}}
                @if(lastPrompt === ''){
                    <app-example-question-generator></app-example-question-generator>
                }
            </div>
        </div>
        <button mat-icon-button class="mt-2 mr-4" [matMenuTriggerFor]="menu"><mat-icon [ngStyle]="{'color': '#CBD5E1'}">more_vert</mat-icon></button>
    </div>
    <mat-drawer-container [hasBackdrop]="false" class="h-[80%]">
        <mat-drawer #drawer [mode]="'side'" class="min-w-[15%] bg-slate-400 max-w-[15%]">
            <div class="h-full bg-slate-800 flex flex-col text-2xl text-slate-200 text-center align-middle">
                <app-csai-history (historicalPromptSelected)="historicalPromptSelected($event)"></app-csai-history>
            </div>
        </mat-drawer>
        <mat-drawer-content class="h-full flex flex-col main-gradient">
            <div class="overflow-y-auto h-full">
                @if(!isPrompting && promptResponse !== ''){
                    <div class="flex flex-row h-full">
                        <div #promptResponseDiv class="mr-2 ml-4 p-4 bg-slate-700 text-slate-100 font-mono subpixel-antialiased font-semibold text-lg w-[50%] max-w-[50%] shadow-xl h-[100%]" >
                            <div class="h-[90%] overflow-y-auto">
                                <markdown [data]="promptResponse"></markdown>
                            </div>
                            <app-csai-feedback [promptId]="activePromptId"></app-csai-feedback>
                        </div>
                        <div class="ml-2 mr-4 bg-slate-700 text-slate-100 w-[50%] shadow-xl h-[100%]">
                            <mat-tab-group (selectedTabChange)="tabChanged($event)" class="bg-slate-200">
                                @for(pdf of pdfSrc; track $index){
                                    <mat-tab label="{{$index+1}}">
                                        @if(currentPdfTabIndex === $index){
                                            <div>
                                                <ngx-extended-pdf-viewer
                                                  [src]="pdf"       
                                                  [height]="prDivH"
                                                  [textLayer]="true"
                                                  [showHandToolButton]="true"
                                                  [showBookModeButton]="false"
                                                  [showPagingButtons]="false"
                                                  [showDrawEditor]="false"
                                                  [showRotateButton]="false"
                                                  [showOpenFileButton]="false"
                                                  appPdfLinkClickDirective (linkClick)="onLinkClick($event)"
                                                    >
                                                </ngx-extended-pdf-viewer>
                                            </div>
                                        }
                                    </mat-tab>
                                }
                            </mat-tab-group>
                        </div>
                    </div>
                }
                @else if (isPrompting) {
                    <div class="flex flex-row items-center align-middle justify-center">
                        <mat-progress-bar class="m-10" mode="indeterminate"></mat-progress-bar>
                    </div>
                }
                @else if(!isPrompting && promptResponse === ''){
                    <div class="flex flex-col justify-center align-middle h-full">
                        <div class="flex flex-row justify-center align-middle">
                            <app-loading-logo [includeGame]="false"></app-loading-logo>
                        </div>
                    </div>
                }
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
    <div class="bg-slate-500 p-4 min-h-[175px]">
        <div class="flex flex-row justify-center align-middle">
            <form class="w-full px-4 py-2">
                <mat-form-field class="w-full form-group mb-0">
                    <mat-label for="promptInput" class="block">Prompt</mat-label>
                    <input matInput
                     [formControl]="promptInput"
                     class="form-control block w-full px-3 py-1.5 bg-clip-padding border border-solid m-0"
                     placeholder="Ex. When do RBIV Warranties Expire?"
                     (keydown.enter)="submit()">
                </mat-form-field>
            </form>
            <button mat-raised-button color="primary" class="mr-5 px-4 py-2 mb-0 min-h-[60px] mt-2" (click)="submit()" [disabled]="!submitEnabled">Submit</button>
        </div>
    </div>
</div>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="reportIssuePressed()">Report Issue</button>
    <button mat-menu-item (click)="speechSettingsPressed()">Speech Settings</button>
</mat-menu>