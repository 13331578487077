<div class="w-full h-full main-gradient overflow-y-hidden flex flex-col">
    <mat-tab-group>
        <mat-tab label="Approve Wiki Requests">
            <ng-template matTabContent>
                <app-wiki-request-review-table></app-wiki-request-review-table>
            </ng-template>
        </mat-tab>
        @if(canAccessApprovedRequests){
            <mat-tab label="View Approved Requests">
                <ng-template matTabContent>
                    <app-approved-requests-table></app-approved-requests-table>
                </ng-template>
            </mat-tab>
        }
    </mat-tab-group>
</div>