<mat-card>
    <mat-card-header class="flex flex-row text-center justify-between items-center">
        <mat-card-title>Integrations Over Time</mat-card-title>
        <button mat-icon-button matTooltip="Change Interval" matTooltipShowDelay="500" [matMenuTriggerFor]="menu">
            <mat-icon>timeline</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        @if(isLoading){
            <div class="flex flex-row justify-center align-center">
                <mat-spinner></mat-spinner>
            </div>
        }
        @else {
            <ngx-charts-area-chart
            [legend]="true"
            [animations]="true"
            [scheme]="colorScheme"
            [xAxis]="true"
            [yAxis]="true"
            [showYAxisLabel]="true"
            [showXAxisLabel]="true"
            [xAxisLabel]="'Date'"
            [yAxisLabel]="'Count'"
            [results]="data">
            </ngx-charts-area-chart>
        }
    </mat-card-content>
</mat-card>

<mat-menu #menu="matMenu">
    <div class="flex flex-col" [formGroup]="timelineForm">
        <mat-form-field (click) = "$event.stopPropagation()" class="pl-2 pr-2">
            <mat-label>Days</mat-label>
            <input matInput type="number" formControlName="days">
        </mat-form-field>
        <mat-form-field (click) = "$event.stopPropagation()" class="pl-2 pr-2">
            <mat-label>Hours</mat-label>
            <input matInput type="number" formControlName="hours">
        </mat-form-field>
        <mat-form-field (click) = "$event.stopPropagation()" class="pl-2 pr-2">
            <mat-label>Minutes</mat-label>
            <input matInput type="number" formControlName="minutes">
        </mat-form-field>
        <mat-form-field (click) = "$event.stopPropagation()" class="pl-2 pr-2">
            <mat-label>Seconds</mat-label>
            <input matInput type="number" formControlName="seconds">
        </mat-form-field>
        <mat-form-field (click) = "$event.stopPropagation()" class="pl-2 pr-2">
            <mat-label>Milliseconds</mat-label>
            <input matInput type="number" formControlName="milliseconds">
        </mat-form-field>
    </div>    
  </mat-menu>