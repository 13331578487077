import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { MatButton } from '@angular/material/button';
import { LoadingLogoComponent } from '../loading-logo/loading-logo.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    standalone: true,
    imports: [LoadingLogoComponent, MatButton, MatProgressSpinner]
})
export class LoginComponent implements OnInit{

  constructor(private authService: MsalService, private router: Router) { }

  ngOnInit(): void {
    if(this.authService.instance.getAllAccounts().length > 0){
      this.router.navigate(['/csai']);
    }
  }

  isLoggingIn = false;
  login(){
    this.isLoggingIn = true;
    this.authService.loginRedirect().subscribe({
      complete: () => {
       this.isLoggingIn = false;
      }
    });
  }
}
