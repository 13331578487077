import { MSALInstanceFactory, MSALInterceptorConfigFactory, MSALGuardConfigFactory } from './app/app.config';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalModule } from '@azure/msal-angular';
import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS, NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { provideMarkdown, MarkdownModule } from 'ngx-markdown';
import { ACE_CONFIG, AceConfigInterface, AceModule } from 'ngx-ace-wrapper';
import { provideHttpClient, withInterceptorsFromDi, withFetch, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
};

if (environment.production) {
  enableProdMode();
}


bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(AceModule, BrowserModule, AppRoutingModule, MatToolbarModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatSnackBarModule, MatBadgeModule, MatMenuModule, MatCardModule, MatSortModule, MatListModule, MatDatepickerModule, MatNativeDateModule, MatTableModule, MatSliderModule, MatPaginatorModule, MatSelectModule, MatTabsModule, MatTooltipModule, MatExpansionModule, MarkdownModule.forRoot(), MatDividerModule, MatSidenavModule, MatDialogModule, ReactiveFormsModule, MatProgressSpinnerModule, MatProgressBarModule, FormsModule, NgxExtendedPdfViewerModule, NgxChartsModule, NgxMatColorPickerModule, MsalModule),
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        {
            provide: ACE_CONFIG,
            useValue: DEFAULT_ACE_CONFIG
        },
        provideMarkdown(),
        { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ]
})
  .catch(err => console.error(err));
