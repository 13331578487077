<mat-card>
    <mat-card-header class="flex flex-row text-center justify-center items-center">
        <mat-card-title>Integration Reporting</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        @if(auditLogDetails === null){
            <div class="flex flex-row justify-center align-center">
                <mat-spinner></mat-spinner>
            </div>
        }
        @else {
            <div class="flex flex-col">
                <div class="flex flex-row p-4 justify-between">
                    <div class="flex flex-row">
                        <div class="text-2xl">
                            Total Changes
                        </div>
                        <div class="text-4xl pl-4">
                            {{ auditLogDetails.totalAudits }}
                        </div>
                    </div>
                    <div>
                        <mat-icon class="text-slate-300" matTooltip="Indicates the amount of total changes to the database in the timeframe">info</mat-icon>
                    </div>
                </div>
                <div class="flex flex-row p-4 justify-between">
                    <div class="flex flex-row">
                        <div class="text-2xl">
                            Successful Changes
                        </div>
                        <div class="text-4xl pl-4">
                            {{ auditLogDetails.totalSuccessAudits }}
                        </div>
                    </div>
                    <div>
                        <mat-icon class="text-green-300" matTooltip="Indicates the amount of total successful changes to the database in the timeframe">info</mat-icon>
                    </div>
                </div>
                <div class="flex flex-row p-4 justify-between">
                    <div class="flex flex-row">
                        <div class="text-2xl">
                            Failed Changes
                        </div>
                        <div class="text-4xl pl-4">
                            {{ auditLogDetails.totalFailedAudits }}
                        </div>
                    </div>
                    <div>
                        <mat-icon class="text-red-300" matTooltip="Indicates the amount of failed changes to the database in the timeframe">info</mat-icon>
                    </div>
                </div>
                <div class="flex flex-row p-4 justify-between">
                    <div class="flex flex-row">
                        <div class="text-2xl">
                            Success Rate
                        </div>
                        @if(auditSuccessRate < 100){
                            <div class="text-4xl pl-4">
                                {{ auditSuccessRate | number:'2.1-2' }} %
                            </div>
                        }
                        @else { 
                            <div class="text-4xl pl-4">
                                {{ auditSuccessRate | number:'3.0' }} %
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </mat-card-content>
</mat-card>