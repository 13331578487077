import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CsaiHistoryService } from '../../services/csai-history.service';
import { GraphResponse } from '../../models/graph-response';
import { debounceTime } from 'rxjs';
import { TreeMapModule } from '@swimlane/ngx-charts';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';

@Component({
    selector: 'app-csai-most-common-prompts',
    templateUrl: './csai-most-common-prompts.component.html',
    styleUrl: './csai-most-common-prompts.component.scss',
    standalone: true,
    imports: [MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatProgressSpinner, TreeMapModule]
})
export class CsaiMostCommonPromptsComponent implements OnInit{

  isLoading = false;
  results: GraphResponse[] = [];

  @Input() filterForm!: FormGroup;

  constructor(private csaiHistory: CsaiHistoryService) {}

  ngOnInit(): void {
    this.getMostCommonPrompts();
    this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.getMostCommonPrompts();
    });
  }


  getMostCommonPrompts(){
    this.isLoading = true;
    this.csaiHistory.getMostCommponPrompts(this.filterForm.get('startDate')?.value, this.filterForm.get('endDate')?.value).subscribe((data) => {
      this.results = data;
      this.isLoading = false;
    });
  }
}
