import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DocumentData, GroupedDocumentData } from '../../models/document-data';
import { CsaiService } from '../../services/csai.service';
import { groupBy } from '../../extensions';
import { RemovePdfComponent } from '../remove-pdf/remove-pdf.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-document-data-tree',
    templateUrl: './document-data-tree.component.html',
    styleUrl: './document-data-tree.component.scss',
    standalone: true,
    imports: [MatFormField, ReactiveFormsModule, MatLabel, MatInput, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatIconButton, MatIcon, MatTooltip, MatDivider, DatePipe, MatProgressSpinner]
})
export class DocumentDataTreeComponent implements OnInit {

  documents: GroupedDocumentData[] = [];
  filteredDocuments: GroupedDocumentData[] = [];

  searchForm: FormGroup = new FormGroup({
    search: new FormControl('')
  });

  loading = false;

  @Output() selectedDocumentPreview: EventEmitter<number> = new EventEmitter<number>();

  constructor(private csaiService: CsaiService, private cdr: ChangeDetectorRef, private matDialog: MatDialog){}

  ngOnInit(): void {
    this.getDocuments();
    this.searchForm.get('search')?.valueChanges.subscribe((search) => {
      this.filteredDocuments = this.documents.filter((doc) => doc.parentName.toLowerCase().includes(search.toLowerCase()));
    });
  }

  getDocuments(){
    this.loading = true;
    this.csaiService.getDocuments().subscribe((documents) => {
      const groupedDocs = groupBy(documents, (doc) => doc.documentName);
      this.documents = Object.keys(groupedDocs).map((key) => {
        return {
          parentId: key,
          parentName: key,
          createdBy: groupedDocs[key][0].createdBy,
          createdDate: groupedDocs[key][0].createdDateTimeUtc,
          documents: groupedDocs[key]
        }
      });
      this.filteredDocuments = this.documents;
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  documentSelected(documentData: DocumentData){
    this.selectedDocumentPreview.emit(documentData.id);
  }

  getContentPreview(document: DocumentData){
    return `${document.content.substring(0, 100)} ...`;
  }

  get groupedDocuments(): GroupedDocumentData[]{
    return this.documents;
  }

  openRemovePdf(documents: DocumentData[]){
    this.matDialog.open(RemovePdfComponent, {width: '25%', height: '25%', data: documents}).afterClosed().subscribe((removed) => {
      if(removed){
        this.getDocuments();
      }
    });
  }
}
