import { Component, EventEmitter, ViewChild } from '@angular/core';
import { PdfDownloadService } from '../../services/pdf-download.service';
import { CsaiService } from '../../services/csai.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadPdfComponent } from '../upload-pdf/upload-pdf.component';
import { DocumentDataTreeComponent } from '../document-data-tree/document-data-tree.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-csai-admin',
    templateUrl: './csai-admin.component.html',
    styleUrl: './csai-admin.component.scss',
    standalone: true,
    imports: [NgClass, MatIcon, MatTooltip, MatIconButton, DocumentDataTreeComponent, NgxExtendedPdfViewerModule]
})
export class CsaiAdminComponent {

  @ViewChild(DocumentDataTreeComponent) documentDataTree!: DocumentDataTreeComponent;

  pdf: Blob | null = null;

  constructor(private csaiService: CsaiService, private pdfDownloadService: PdfDownloadService, private matDialog: MatDialog){}

  previewPdf($event: number){
    this.csaiService.getPdfLink($event).subscribe((res) => {
      this.pdfDownloadService.downloadPdf(res).subscribe((pdf) => {
        this.pdf = pdf;
      });
    });
  }
  closePdf(){
    this.pdf = null;
  }
  openUploadPdf(){
    this.matDialog.open(UploadPdfComponent, {width: '50%', height: '50%'}).afterClosed().subscribe((result) => {
      (result.fileAdded as EventEmitter<void>).subscribe(() => {
        this.documentDataTree.getDocuments();
      });
    });
  }
}
