import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WarehouseLocation } from "../models/location-mapping-data";
import { environment } from "../../environments/environment";
import { BaseResponse } from "../models/base-response";

@Injectable({
    providedIn: 'root'
})

export class LocationMappingService {
    constructor(private httpClient : HttpClient) { }

    getLocationMappings(): Observable<WarehouseLocation[]>{
        return this.httpClient.get<WarehouseLocation[]>(`${environment.apiConfig.uri}api/v1/Shopify/GetLocationMappings`);
    }

    getDomains(): Observable<string[]>{
        return this.httpClient.get<string[]>(`${environment.apiConfig.uri}api/v1/Shopify/GetDomains`);
    }

    addDomainToLocation(netsuiteWarehouseId: number, domain: string, warehouseName: string): Observable<BaseResponse>{
        return this.httpClient.post<BaseResponse>(`${environment.apiConfig.uri}api/v1/Shopify/AddLocationMapping`, { netsuiteWarehouseId, domain, warehouseName });
    }

    removeDomainFromLocation(netsuiteWarehouseId: number, domain: string, shopifyLocationId: number, warehouseName: string): Observable<BaseResponse> {
        return this.httpClient.request<BaseResponse>('delete', `${environment.apiConfig.uri}api/v1/Shopify/DeleteLocationMapping`, {
          body: {netsuiteWarehouseId, domain, shopifyLocationId, warehouseName }
        });
      }
}