import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { AppNotificationService } from '../../services/app-notification.service';
import { CsaiService } from '../../services/csai.service';
import { LoadingCallbackService } from '../../services/loading-callback.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-upload-pdf',
    templateUrl: './upload-pdf.component.html',
    styleUrl: './upload-pdf.component.scss',
    standalone: true,
    imports: [MatHint, MatOption, MatSelect, MatDialogTitle, CdkScrollable, MatDialogContent, MatButton, MatIcon, MatFormField, ReactiveFormsModule, MatLabel, MatInput, MatIconButton, MatTooltip, MatDialogActions]
})
export class UploadPdfComponent implements OnInit {

  form: FormGroup = new FormGroup({
    file: new FormControl<File[]>([]),
    sku: new FormControl<string>(''),
    role: new FormControl<string[]>([])
  })

  roles: string[] = [];

  @Output() fileAdded: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<UploadPdfComponent>, 
    private notification: AppNotificationService, 
    private csaiService: CsaiService,
    private loadingCallbackService: LoadingCallbackService){}

  ngOnInit(): void {
    this.getRoles();
  }

  getRoles() {
    this.csaiService.getRoles().subscribe((roles) => {
      this.roles = roles;
    });
  }

  cancel(){
    this.dialogRef.close();
  }

  onFileSelected($event: any){
    if($event.target.files[0]?.type !== 'application/pdf'){
      this.notification.spawnError('File selected is not of type PDF');
    }
    else{
      this.form.get('file')?.setValue( [...this.files, $event.target.files[0]]);
    }
  }

  onConfirm(){

    const callbackId: string[] = this.files.map(() => crypto.randomUUID());

    this.files.forEach((file: File, index: number) => 
    {
        this.loadingCallbackService.addCallback(`Uploading PDF - ${file.name}`, callbackId[index], () => this.fileAdded.emit());

        this.csaiService.uploadPdf(file, this.form.get('sku')?.value, this.form.get('role')?.value).subscribe(() => {
          this.notification.spawnAlert('Pdf Uploaded Successfully');
          this.loadingCallbackService.expireCallback(callbackId[index], true);
        }, () => {
          this.notification.spawnError('Error uploading PDF');
          this.loadingCallbackService.expireCallback(callbackId[index], false);
        });

    });

    this.notification.spawnAlert(`${this.files.length > 1 ? 'PDF\'s': 'PDF'} Uploaded. This may take a while`);
    this.dialogRef.close({fileAdded: this.fileAdded});
  }

  removeFile(file: File){
    this.form.get('file')?.setValue(this.files.filter((f: File) => f !== file));
  }

  get confirmButtonDisabled(){
    return this.files.length === 0;
  }

  get files(){
    return this.form.get('file')?.value;
  }
}
