<div class="main-gradient w-full h-full flex flex-col">
    <div class="flex flex-row">
        <div class="flex flex-col h-[90vh] overflow-y-auto" [ngClass]="pdf === null ? ' w-full' : 'w-[50%]'">
            <div class="flex flex-row justify-center align-middle">
                <div class="text-4xl text-center text-slate-300 pt-3 pb-3">
                    Documents
                </div>
                <mat-icon class="text-sm text-slate-50" matTooltip="Documents that the model will base its answers on.">info</mat-icon>
                <button mat-icon-button (click)="openUploadPdf()" class="mt-3 ml-1" matTooltip="Add New Document">
                    <mat-icon class="text-slate-50">add_circle</mat-icon>
                </button>
            </div>
            <app-document-data-tree #documentDataTree class="m-2" (selectedDocumentPreview)="previewPdf($event)"></app-document-data-tree>
        </div>
        @if(pdf !== null){
            <div class="flex flex-col w-[50%]">
                <div class="flex flex-row justify-center align-middle">
                    <div class="text-4xl text-center text-slate-300 pt-3 pb-3">
                        Preview
                    </div>
                    <button mat-icon-button (click)="closePdf()">
                        <mat-icon class="text-sm text-slate-50">close</mat-icon>
                    </button>
                </div>
                <ngx-extended-pdf-viewer 
                [src]="pdf" 
                [height]="'85vh'"
                [textLayer]="true" 
                [showHandToolButton]="true"
                [showBookModeButton]="false"
                [showPagingButtons]="false"
                [showDrawEditor]="false"
                [showRotateButton]="false"
                [showOpenFileButton]="false">
                </ngx-extended-pdf-viewer>
            </div>
        }     
    </div>
</div>
