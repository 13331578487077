import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { YouTubePlayer} from '@angular/youtube-player'
@Component({
  selector: 'app-youtube-embed',
  standalone: true,
  imports: [YouTubePlayer, MatDialogModule],
  templateUrl: './youtube-embed.component.html',
  styleUrl: './youtube-embed.component.scss'
})
export class YoutubeEmbedComponent implements AfterViewInit {

  @ViewChild(YouTubePlayer) youTubePlayer!: YouTubePlayer;
  @ViewChild('container') container!: ElementRef;

  youtubeId = '';

  constructor(private matDialog: MatDialogRef<YoutubeEmbedComponent>,
    @Inject(MAT_DIALOG_DATA) data: string, 
    private changeDetectorRef: ChangeDetectorRef
  ){
    this.youtubeId = data.split('v=')[1];
  }


  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize(): void {

    this.youTubePlayer.height = this.container.nativeElement.offsetHeight;
    this.youTubePlayer.width = this.container.nativeElement.offsetWidth-50; 
    this.changeDetectorRef.detectChanges();
  }

  exit(){
    this.matDialog.close();
  }

}
