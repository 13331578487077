import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RoleService } from '../services/role.service';
import { firstValueFrom } from 'rxjs';
import { LoadingService } from '../services/loading.service';

export const roleGuard: CanActivateFn = async (route, _) => {

  const roleService = inject(RoleService);
  const loadingService = inject(LoadingService);

  loadingService.show();

  const roles = route.data.roles as string[];
  if(!roles){
    loadingService.hide();
    return true;
  }
  
  await firstValueFrom(roleService.refreshRoles());

  loadingService.hide();

  return roleService.roles?.some(x => roles.includes(x)) ?? false;
};
