<h2 mat-dialog-title class="text-center">Sorry, the app is under maintenance, or you were unable to login. Please check back later.</h2>
<mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Snake">
            <app-snake></app-snake>
        </mat-tab>
        <mat-tab label="Pong">
            <app-pong></app-pong>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="w-[48.5%]" mat-raised-button color="primary" (click)="leave()" cdkFocusInitial>Leave</button>
    <button class="w-[48.5%]" mat-raised-button color="warn" (click)="restart()">Restart</button>
</mat-dialog-actions>

