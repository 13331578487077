import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CSAIAudit } from '../models/csai-audit';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';
import { GraphResponse } from '../models/graph-response';

@Injectable({
  providedIn: 'root'
})
export class CsaiHistoryService {
  csaiAudits: CSAIAudit[] = [];

  constructor(private httpClient: HttpClient) { }

  getAudits(refresh: boolean){
    if(refresh || this.csaiAudits.length === 0){
      this.httpClient.get<CSAIAudit[]>(`${environment.apiConfig.uri}api/v1/csai/history`).subscribe((audits) => {
        this.csaiAudits = audits;
      });
    }
    return of(this.csaiAudits);
  }

  getPromptsByUser(fromDate: Date, toDate: Date){
    return this.httpClient.get<GraphResponse[]>(`${environment.apiConfig.uri}api/v1/csai/PromptsByUser?startDate=${fromDate?.toISOString() ?? ''}&endDate=${toDate?.toISOString() ?? ''}`);
  }

  getMostCommponPrompts(fromDate: Date, toDate: Date){
    return this.httpClient.get<GraphResponse[]>(`${environment.apiConfig.uri}api/v1/csai/MostCommonlyUsedPrompts?startDate=${fromDate?.toISOString() ?? ''}&endDate=${toDate?.toISOString() ?? ''}`);
  }

  getMostCommonPdfResponses(fromDate: Date, toDate: Date){
    return this.httpClient.get<GraphResponse[]>(`${environment.apiConfig.uri}api/v1/csai/MostCommonlyUsedPdfPages?startDate=${fromDate?.toISOString() ?? ''}&endDate=${toDate?.toISOString() ?? ''}`);
  }

  getPromptsOverTime(fromDate: Date, toDate: Date){
    return this.httpClient.get<GraphResponse[]>(`${environment.apiConfig.uri}api/v1/csai/PromptsOverTime?startDate=${fromDate?.toISOString() ?? ''}&endDate=${toDate?.toISOString() ?? ''}`);
  }
}
