import { AfterContentChecked, Component, OnInit, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Showroom } from '../../models/showroom';
import { MatSelect } from '@angular/material/select';
import { ShowroomService } from '../../services/showroom-service';
import { AppNotificationService } from '../../services/app-notification.service';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AddShowroomComponent } from '../add-showroom/add-showroom.component';
import { ShowroomCsvComponent } from '../showroom-csv/showroom-csv.component';
import { ShowroomDeleteComponent } from '../showroom-delete-component/showroom-delete.component';

@Component({
  selector: 'app-showrooms-table',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule, MatDialogModule, ShowroomDeleteComponent, MatProgressSpinnerModule],
  templateUrl: './showrooms-table.component.html',
  styleUrl: './showrooms-table.component.scss'
})
export class ShowroomsTableComponent implements OnInit, AfterViewInit, AfterContentChecked{
    showRoomDataSource: Showroom[] = [];
    showRoomDataResponse: Showroom[] = [];

    loading = true;
    isOperationInProgress = false;

    @ViewChildren('showroomSelect') showroomSelect!: QueryList<MatSelect>;


    displayColumns: string[] = ['Name', 'Address', 'City', 'State', 'Postcode', 'Country', 'Phone', 'Email','Website', 'Latitude', 'Longitude', 'Actions'];

    constructor(private cdr: ChangeDetectorRef, private showroomService: ShowroomService, private notifService: AppNotificationService, private dialog: MatDialog) { }

    ngOnInit(): void {
      this.getShowrooms();
    }

    ngAfterViewInit(): void {
      this.cdr.detectChanges();
      this.getShowrooms();
    }

    ngAfterContentChecked(): void {
      this.cdr.detectChanges();
    }

    getShowrooms() {
      this.loading = true;
      this.showroomService.getShowrooms().subscribe({
        next: (data) => {
          this.showRoomDataSource = data;
          this.loading = false;
        },
        error: () => {
          this.notifService.spawnError('Failed To Get Showrooms');
        }
      });
    }

    deleteShowroom(showroom: Showroom) {
      const dialogRef = this.dialog.open(ShowroomDeleteComponent, {
        width: '250px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.performDelete(showroom);
        }
      });
    }

    openAddShowroomDialog() {
      const dialogRef = this.dialog.open(AddShowroomComponent, {
        width: '450px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.addShowroom(result);
        }
      });
    }

    openImportCsvDialog() {
      const dialogRef = this.dialog.open(ShowroomCsvComponent, {
        width: '450px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.importCsv(result);
        }
      })
    }

    performDelete(showroom: Showroom) {
      this.isOperationInProgress = true;
      this.showroomService.deleteShowroom(showroom.id).subscribe({
          next: () => {
              this.notifService.spawnAlert('Showroom Delete Successful');
              this.getShowrooms();
          },
          error: () => {
              this.notifService.spawnError('Error Deleting Showroom');
          },
          complete: () => {
            this.isOperationInProgress = false;
          }
      });
  }
    addShowroom(showroomData: Showroom) {
      this.isOperationInProgress = true;
      this.showroomService.addShowroom(showroomData).subscribe({
        next: () => {
          this.notifService.spawnAlert('Showroom Added Successfully');
          this.getShowrooms();
        },
        error: () => {
          this.notifService.spawnError('Error Adding Showroom');
        },
        complete: () => {
          this.isOperationInProgress = false;
        }
      });
    }

    importCsv(file: File) {
      this.isOperationInProgress = true;
      const formData = new FormData();
      formData.append('showroomCsv', file, file.name);
      this.showroomService.processCsv(formData).subscribe({
        next: () => {
          this.notifService.spawnAlert('Csv Processed Successfully');
          this.getShowrooms();
        },
        error: () => {
          this.notifService.spawnError('Error Parsing Csv');
        },
        complete: () => {
          this.isOperationInProgress = false;
        }
      });
    }
}
