import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { CallbackNotificationsComponent } from '../callback-notifications/callback-notifications.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
@Component({
    selector: 'app-power-toolbar',
    templateUrl: './power-toolbar.component.html',
    styleUrl: './power-toolbar.component.scss',
    standalone: true,
    imports: [MatToolbar, MatIconButton, MatIcon, CallbackNotificationsComponent, MatButton]
})
export class PowerToolbarComponent implements OnInit{

  environmentName = environment.title;
  name: string | undefined = undefined;
  @Output() drawerToggled: EventEmitter<void> = new EventEmitter<void>();

  constructor(private authService: MsalService, private router: Router) {

  }
  ngOnInit(): void {
    this.name = this.authService.instance.getActiveAccount()?.username;
  }

  logout(){
    this.authService.logoutRedirect().subscribe();
    this.router.navigate(['/']);
  }


  toggleDrawer(){
    this.drawerToggled.emit();
  }
}
