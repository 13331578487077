import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ApprovedRequest } from '../../models/approved-request';
import { WikiService } from '../../services/wiki.service';
import { MatInputModule } from '@angular/material/input';
import { AppNotificationService } from '../../services/app-notification.service';

@Component({
  selector: 'app-approved-requests-table',
  standalone: true,
  imports: [MatTable, MatFormFieldModule, MatInputModule, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFormField, ReactiveFormsModule, MatLabel, MatSelect, MatOption, MatProgressSpinner, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow],
  templateUrl: './approved-requests-table.component.html',
  styleUrl: './approved-requests-table.component.scss'
})
export class ApprovedRequestsTableComponent implements OnInit{
  dataSource: MatTableDataSource<ApprovedRequest> = new MatTableDataSource<ApprovedRequest>();

  loading = false;
  none = false;

  forms: FormGroup[] = [];

  confirmLoading = new Map<number, boolean>();

  displayedColumns: string[] = ['requesterEmail', 'requesterName', 'sku', 'requestDetails', 'approvalNotes', 'postApprovalNotes', 'closeAction'];

  constructor(private wikiService: WikiService, private appNotificationService: AppNotificationService) { }

  ngOnInit(): void {
    this.getApprovedRequests();
  }

  getApprovedRequests() {
    this.loading = true;
    this.wikiService.getApprovedRequests().subscribe((requests) => {
      this.dataSource.data = requests;
      this.forms = [];

      requests.forEach((r) => {
        this.forms.push(new FormGroup({
          postApprovalNotes: new FormControl(r.postApprovalNotes ?? '')
        }));
      });

      if(requests.length === 0){
        this.none = true;
      } else {
        this.none = false;
      }

      this.loading = false;
    });
  }

  writeAdditionalNotes(index: number) {
    this.confirmLoading.set(index, true);
    this.wikiService.addPostApprovalNotes(this.dataSource.data[index].id, this.forms[index].get('postApprovalNotes')?.value).subscribe(() => {
      this.confirmLoading.set(index, false);
      this.getApprovedRequests();
      this.appNotificationService.spawnAlert(`Additional notes added successfully`);
    });
  }

  closeRequestClicked(index: number){
    this.closeRequest(index, this.dataSource.data[index].id);
  }

  closeRequest(index: number, id: number) {
    this.confirmLoading.set(index, true);
    this.wikiService.closeRequest(id).subscribe(() => {
      this.confirmLoading.set(index, false);
      this.getApprovedRequests();
      this.appNotificationService.spawnAlert(`Request closed successfully`);
    });
  }
}

