<div class="game-container flex flex-col">
    <div class="flex flex-row">
        <button mat-icon-button (click)="start()" [disabled]="gameRunning"><mat-icon>play_arrow</mat-icon></button>
        <!-- <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button> -->
    </div>
    <div class="p-5">
        Score: {{currentScore}}
    </div>
    <div class="p-5">
        High Score: {{highScore}}
    </div>
    <canvas #gameCanvas width="400" height="400"></canvas>
</div>

<!-- <mat-menu #menu="matMenu">
    <mat-form-field>
        <input matInput [ngxMatColorPicker]="picker" [formControl]="colorCtr">
        <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
        <ngx-mat-color-picker #picker [touchUi]="true" [color]="color"></ngx-mat-color-picker>
    </mat-form-field>
</mat-menu> -->