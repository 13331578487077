import { Component, Input, OnInit } from '@angular/core';
import { AuditLogDetails } from '../../models/audit-log-details';
import { AuditLogService } from '../../services/audit-log.service';
import { FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { DecimalPipe } from '@angular/common';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';

@Component({
    selector: 'app-audit-card',
    templateUrl: './audit-card.component.html',
    styleUrl: './audit-card.component.scss',
    standalone: true,
    imports: [MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatProgressSpinner, MatIcon, MatTooltip, DecimalPipe]
})
export class AuditCardComponent implements OnInit{
  auditLogDetails: AuditLogDetails | null = null;

  @Input() integrationForm!: FormGroup;

  constructor(private auditLogService: AuditLogService) { }

  ngOnInit(): void {
    this.getAuditLogDetails();
    
    this.integrationForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.getAuditLogDetails();
      });
  }

  getAuditLogDetails(){
    this.auditLogDetails = null;
    this.auditLogService.getAuditLogDetails(
      this.integrationForm.get('startDate')?.value, 
      this.integrationForm.get('endDate')?.value,
      this.integrationForm.get('modelTypeSelection')?.value,
      this.integrationForm.get('integrationTypeSelection')?.value)
      .subscribe((data) => {
      this.auditLogDetails = data;
    });
  }

  get auditSuccessRate(){
    return this.auditLogDetails !== null ? (this.auditLogDetails.totalSuccessAudits/this.auditLogDetails.totalAudits)*100 : 0; 
  }
}
