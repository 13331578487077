<h2 mat-dialog-title class="text-center">Google Sheet</h2>
<mat-dialog-content class="flex flex-row justify-center items-center w-full" #container>
    <iframe
        width="100%"
        height="100%"
        [src]="googleSheetUrl"
        frameborder="0"
        allowfullscreen>
    </iframe>
</mat-dialog-content>
<mat-dialog-actions>
    <button class="w-full" mat-raised-button color="warn" (click)="exit()" cdkFocusInitial>Exit</button>
</mat-dialog-actions>
