import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpeechService {

  voices: SpeechSynthesisVoice[] = [];

  rate: number;
  pitch: number;
  volume: number;
  voiceName: string | null = null;

  private defaultVoice = 'Microsoft Zira - English (United States)';

  constructor(){
    this.setSpeech().then((vO) => 
      {
        this.voices = vO as SpeechSynthesisVoice[];
      });

      this.rate = localStorage.getItem('rate') ? parseFloat(localStorage.getItem('rate') as string) : 1;
      this.pitch = localStorage.getItem('pitch') ? parseFloat(localStorage.getItem('pitch') as string) : 1;
      this.volume = localStorage.getItem('volume') ? parseFloat(localStorage.getItem('volume') as string) : 1;
      this.voiceName = localStorage.getItem('voiceName') ?? null;
  }

  makeSpeakingRequest(text: string){
    const speech = new SpeechSynthesisUtterance();
    speech.text = text;
    speech.rate = this.rate;
    speech.pitch = this.pitch;
    speech.volume = this.volume;
    if(!this.voiceName){
      this.voiceName = this.voices.find(x => x.name === this.defaultVoice)?.name ?? this.voices[0].name;
    }
    const voice = this.voices.find((v) => v.name === this.voiceName);
    if(voice){
      speech.voice = voice;
    }
    speechSynthesis.speak(speech);
  }

  cancelCurrentSpeaking(){
    speechSynthesis.cancel();
  }

  setSpeech() {
    return new Promise(
        function (resolve) {
            const synth = window.speechSynthesis;
            const id = setInterval(() => {
                if (synth.getVoices().length !== 0) {
                    resolve(synth.getVoices());
                    clearInterval(id);
                }
            }, 10);
        }
    )
}
}
