import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TypewriterService {

  typeWriterMap: Map<string, [string, number]> = new Map<string, [string, number]>();

  startTypeWriter(id: string, text: string, outputTextReference: FormControl, speed: number){
    this.typeWriterMap.set(id, [text, 0]);
    this._type(id, outputTextReference, speed);
  }

  private _type(id: string, outputTextReference: FormControl, speed: number){
    const mapValue = this.typeWriterMap.get(id)!;

    if(mapValue[1] >= mapValue[0].length){
      this.typeWriterMap.delete(id);
      return;
    }

    outputTextReference.setValue(outputTextReference.value + mapValue[0].charAt(mapValue[1]));
    mapValue[1]++;
    setTimeout(() => this._type(id, outputTextReference, speed), speed);
  }
}
