import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdfDownloadService {

  private httpClient: HttpClient;
  constructor(private httpBackend : HttpBackend) {
    this.httpClient = new HttpClient(httpBackend);
   }

  downloadPdf(link: string){
    return this.httpClient.get(link, {responseType: 'blob', headers: {Accept: 'application/pdf'}});
  }
}
