import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuditLogService } from '../../services/audit-log.service';
import { forkJoin } from 'rxjs';
import { AuditTableComponent } from '../audit-table/audit-table.component';
import { AuditSuccessGraphComponent } from '../audit-success-graph/audit-success-graph.component';
import { AuditCardComponent } from '../audit-card/audit-card.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatHint, MatSuffix } from '@angular/material/form-field';
@Component({
    selector: 'app-integration-reporting',
    templateUrl: './integration-reporting.component.html',
    styleUrl: './integration-reporting.component.scss',
    standalone: true,
    imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatDatepickerInput, MatHint, MatDatepickerToggle, MatSuffix, MatDatepicker, MatSelect, MatOption, MatIcon, MatTooltip, AuditCardComponent, AuditSuccessGraphComponent, AuditTableComponent]
})
export class IntegrationReportingComponent implements OnInit, AfterViewChecked{

  formGroup!: FormGroup;

  modelTypes: string[] = [];
  integrationTypes: string[] = [];
  
  constructor(private formBuilder: FormBuilder, private auditLogService: AuditLogService, private cdr: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    forkJoin([this.auditLogService.getModelTypes(), this.auditLogService.getIntegrationTypes()]).subscribe(([modelTypes, integrationTypes]) => {
      this.modelTypes = modelTypes;
      this.integrationTypes = integrationTypes;
    });

    const now = new Date();
    now.setDate(now.getDate() + 1);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);
    this.formGroup = this.formBuilder.group({
      startDate: [thirtyDaysAgo],
      endDate: [now],
      modelTypeSelection: [''],
      integrationTypeSelection: [''],
      successSelection: '',
      idSelection: '',
    });

    
  }

}
