export const environment = {
    production: false,
    title: "Sandbox",
    msalConfig: {
        auth: {
            clientId: "68fc22d9-60ba-48b1-914a-69b2d9623328",
            authority: "https://zlineb2csandbox.b2clogin.com/zlineb2csandbox.onmicrosoft.com/B2C_1_SignInSignUp",
            authorityDomain: "zlineb2csandbox.b2clogin.com"
        }
    },
    apiConfig: {
        scopes: ["https://zlineb2csandbox.onmicrosoft.com/b4aff5a0-2ea7-4665-a0cb-ba3746c4dfa6/App.User"],
        uri: "https://sandbox.zline-integrations.com/",
        mapServiceUri: "https://sandbox.map.zline-integrations.com/Map"
    }
};
