import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CsaiPromptsOverTimeComponent } from '../csai-prompts-over-time/csai-prompts-over-time.component';
import { CsaiMostCommonPdfResponsesComponent } from '../csai-most-common-pdf-responses/csai-most-common-pdf-responses.component';
import { CsaiMostCommonPromptsComponent } from '../csai-most-common-prompts/csai-most-common-prompts.component';
import { CsaiPromptsByUserComponent } from '../csai-prompts-by-user/csai-prompts-by-user.component';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'app-csai-reporting',
    templateUrl: './csai-reporting.component.html',
    styleUrl: './csai-reporting.component.scss',
    standalone: true,
    imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, CsaiPromptsByUserComponent, CsaiMostCommonPromptsComponent, CsaiMostCommonPdfResponsesComponent, CsaiPromptsOverTimeComponent]
})
export class CsaiReportingComponent implements OnInit{


  
  ngOnInit(): void {
    const now = new Date();
    now.setDate(now.getDate() + 1);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);

    this.filterForm =  new FormGroup({
      startDate: new FormControl(thirtyDaysAgo),
      endDate: new FormControl(now),
    });
  }

  filterForm!: FormGroup;

}
